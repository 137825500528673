import { autocompleteFormat, brandDataObject } from "../type/BrandType";

export const DataSorting = (sortingType: string, data: brandDataObject[]) => {
  let dataRes: brandDataObject[] = [];
  if (sortingType == "Descending") {
    dataRes = [...data].sort((a, b) => (a.brand_name > b.brand_name ? -1 : 1));
  } else if (sortingType == "Ascending") {
    dataRes = [...data].sort((a, b) => (a.brand_name > b.brand_name ? 1 : -1));
  }

  return dataRes;
};

export const DataAutoComplete = (data: brandDataObject[]) => {
  let autocompleteData: autocompleteFormat[] = [];
  for (let i = 0; i < data.length; i++) {
    autocompleteData.push({
      label: data[i].brand_name,
      value: data[i].brand_name,
    });
  }
  return autocompleteData;
};

export function Paginate(arr: any, size: any) {
  return arr.reduce((acc: any, val: any, i: number) => {
    let idx = Math.floor(i / size);
    let page: number[] = acc[idx] || (acc[idx] = []);
    page.push(val);

    return acc;
  }, []);
}

export const PaginationProcess = (
  autocompleteVal: string,
  sortingVal: string,
  brandData: brandDataObject[],
  currentPage: number
) => {
  const pageSize = 10;

  let dataFiltered = DataSorting(sortingVal, brandData);
  if (autocompleteVal)
    dataFiltered = dataFiltered.filter(
      (data) =>
        JSON.stringify(data)
          .toLowerCase()
          .indexOf(autocompleteVal.toLowerCase()) !== -1
    );

  let pages = Paginate(dataFiltered, pageSize);

  let page = pages[currentPage - 1];
  if (autocompleteVal) {
    page =
      typeof pages[currentPage - 1] == "undefined"
        ? pages[currentPage - 2]
        : pages[currentPage - 1];
  }

  return { page, dataFiltered };
};
