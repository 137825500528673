import React, { useState } from "react";
import PackageCategory from "../../PackageCategory";
import { dummyDataPackage } from "../../DummyData";

type packageMenuType = {
  packageCategory: string;
  packageNameList: { packageName: string }[];
};

interface Props {
  PackageMenuTooltipHandler?: (type: string) => void;
  SelectedItemHandler: (type: { id: string; itemName: string }) => void;
}

function PackageMenu({
  PackageMenuTooltipHandler,
  SelectedItemHandler,
}: Props) {
  const [selectedPackage, setSelectedPackage] = useState<string>();

  const HandleSelectedPackage = (packageName: string) => {
    setSelectedPackage(packageName);
    SelectedItemHandler({ id: "", itemName: packageName });
  };

  return (
    <div className=" w-full h-full">
      {dummyDataPackage.map((value: packageMenuType) => (
        <PackageCategory
          key={value.packageCategory}
          HandleSelectedPackage={HandleSelectedPackage}
          selectedPackage={selectedPackage}
          data={value}
          PackageMenuTooltipHandler={PackageMenuTooltipHandler}
          SelectedItemHandler={SelectedItemHandler}
        />
      ))}
    </div>
  );
}

export default PackageMenu;
