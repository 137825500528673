import { baseApi } from "../../app/Redux/baseApi";
import { Endpoints } from "../../constants/EndPoints";
import { Dropdown } from "./types";

export const TypeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTypeDropdown: builder.query<Dropdown[], void>({
      query: () => ({
        url: Endpoints.getTypeDropdown,
        method: "GET",
      }),
      providesTags: (result, error, page) =>
        result ? ["ProductTypeDropdown"] : [],
      transformResponse: (response: { data: Dropdown[] }) => {
        return response.data;
      },
    }),
    createType: builder.mutation<any, { type_name: string }>({
      query: (body) => ({
        url: Endpoints.createType,
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error) => (!error ? ["ProductTypeDropdown"] : []),
    }),
    deleteType: builder.mutation<any, string>({
      query: (id: string) => ({
        url: Endpoints.deleteType + id,
        method: "DELETE",
      }),
      invalidatesTags: (_, error) => (!error ? ["ProductTypeDropdown"] : []),
    }),
  }),
});

export const {
  useGetTypeDropdownQuery,
  useCreateTypeMutation,
  useDeleteTypeMutation,
} = TypeApi;
