import React, { HTMLProps } from "react";
import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";
import { Tabs, TagType } from "antd";

type item = {
  key: string;
  icon?: any;
};

interface Props {
  items: item[];
  defaultActiveKey?: string | "";
  className?: string | "";
  labelClassName?: string | "";
  onChange?: (value: any) => void;
}

function TabComponent(props: Props) {
  let { items, defaultActiveKey, className, labelClassName, onChange } = props;
  let itemsIconArray: string[] = [];
  let itemsKeyArray: string[] = [];
  items.map((value) => itemsIconArray.push(value.icon));
  items.map((value) => itemsKeyArray.push(value.key));

  return (
    <Tabs
      className={className}
      defaultActiveKey={defaultActiveKey}
      onChange={onChange}
      items={itemsIconArray.map((Icon, i) => {
        const key = itemsKeyArray[i];

        return {
          label: (
            <span className={labelClassName}>
              {Icon && <Icon />}
              {key}
            </span>
          ),
          key: key,
          children: key,
        };
      })}
    />
  );
}

export default TabComponent;
