import { createApi } from "@reduxjs/toolkit/query/react";
import customBaseQuery from "./customBaseQuery";

export const tagTypes = [
  "ProductCategory",
  "ProductBrand",
  "Package",
  "PackageCategory",
  "Product",
  "PackageDetail",
  "ProductType",
];

export const baseApi = createApi({
  reducerPath: "api",
  baseQuery: customBaseQuery,
  endpoints: () => ({}),
  tagTypes: tagTypes,
});
