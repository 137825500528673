import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from "@reduxjs/toolkit/dist/query"

const API_URL = process.env.REACT_APP_API_URL
console.log(API_URL)

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
})

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOption) => {
  let result = await baseQuery(args, api, extraOption)

  return result
}

export default customBaseQuery
