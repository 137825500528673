import { useState } from "react"
import { ButtonComponent } from "../../../../components"
import { Divider, Input, Upload, Form } from "antd"
import { InboxOutlined } from "@ant-design/icons"
import { CreateIcon, UpdateIcon } from "../../../../assets"
import {
  useCreateParentCategoryMutation,
  useUpdateCategoryMutation,
} from "../../../../features/Category/api"
import type { UploadFile } from "antd/es/upload/interface"
import type { UploadProps } from "antd/es/upload"

const { Dragger } = Upload
const FormItem = Form.Item

interface Props {
  selectedItem: { id: string; parentCategoryName: string; imageUrl: string }
  cancelButtonOnClick: Function
  categoryModal: boolean
  popupName: string
}

function PopUpAddUpdateParentCategory({
  selectedItem,
  cancelButtonOnClick,
  categoryModal,
  popupName,
}: Props) {
  const updtVisFlag: boolean = popupName == "Update Parent" ? true : false
  const defaultImageVal: any = updtVisFlag
    ? {
        url: selectedItem.imageUrl,
        uid: selectedItem.id,
        name: "firstImage",
        type: "image/png",
      }
    : {
        url: "",
        uid: "",
        name: "",
        type: "",
      }

  const [createParentCategoryMutation] = useCreateParentCategoryMutation()
  const [updateParentCategoryMutation] = useUpdateCategoryMutation()
  const [fileList, setFileList] = useState<UploadFile>(defaultImageVal)
  const [previewImage, setPreviewImage] = useState(defaultImageVal.url)

  const OnFinishSubmitHandler = async (values: any) => {
    var bodyFormData = new FormData()

    bodyFormData.append("name", values.ParentCategoryName)
    bodyFormData.append("image", values.upload[0].originFileObj)
    bodyFormData.append("image_url", selectedItem.imageUrl)
    // new Response(bodyFormData).text().then(console.log);

    if (popupName == "Add Parent") {
      await createParentCategoryMutation(bodyFormData)
        .unwrap()
        .catch((err: any) => console.log(err))
        .finally(() => {
          cancelButtonOnClick()
        })
    }

    if (popupName == "Update Parent") {
      await updateParentCategoryMutation({
        id: selectedItem.id,
        body: bodyFormData,
      })
        .unwrap()
        .catch((err: any) => console.log(err))
        .finally(() => {
          cancelButtonOnClick()
        })
    }
  }
  const handleChange: UploadProps["onChange"] = (info: any) => {
    const isImg =
      info.file.type === "image/jpeg" ||
      info.file.type === "image/jpg" ||
      info.file.type === "image/png"
    if (!isImg) return
    if (info.file.size > 3000000) {
      return
    }
    let reader = new FileReader()
    reader.onload = (e: any) => setPreviewImage(e.target.result)
    reader.readAsDataURL(info.file.originFileObj)
    setFileList(info.file)
  }

  return (
    <div className="w-[1000px]">
      <div className="w-full flex justify-start items-center text-4xl font-bold font-serif">
        <img className="w-[40px]" src={updtVisFlag ? UpdateIcon : CreateIcon} />
        <div className="ml-2">{popupName} Category</div>
      </div>
      <Divider />
      <Form
        onFinish={OnFinishSubmitHandler}
        name="ParentCategoryPopup"
        className="w-full flex items-start flex-col text-lg font-semibold"
      >
        {updtVisFlag && (
          <div className=" px-2 py-7 flex w-full items-center">
            <div className="w-[30%] h-full flex justify-start">
              Parent Category ID
            </div>
            <div className="w-[20%]"></div>
            <div className=" w-[50%]">
              <FormItem noStyle>
                <Input
                  disabled
                  defaultValue={selectedItem.id}
                  size="large"
                  placeholder="Input Parent Category Name"
                />
              </FormItem>
            </div>
          </div>
        )}
        <div
          className={`p-2 flex w-full items-center ${
            updtVisFlag ? "" : "mt-4"
          }`}
        >
          <div className=" w-[30%] h-full flex justify-start pb-6">
            Parent Category Name
          </div>
          <div className="w-[20%]"></div>
          <div className=" w-[50%]">
            <FormItem
              name="ParentCategoryName"
              initialValue={updtVisFlag ? selectedItem.parentCategoryName : ""}
              rules={[
                {
                  required: true,
                  message: "Please input Parent Category Name",
                },
              ]}
            >
              <Input
                size="large"
                defaultValue={
                  updtVisFlag ? selectedItem.parentCategoryName : ""
                }
                placeholder="Input Parent Category Name"
              />
            </FormItem>
          </div>
        </div>
        <div className="p-2 flex w-full items-center">
          <div className="w-[30%] flex flex-col justify-start">
            <div className="flex">Category Image</div>
            <div className="flex text-xs mt-1">
              <span className=" text-red-600">*</span>Size should be less than
              3MB
            </div>
            <div className="flex text-xs">
              <span className=" text-red-600">*</span>Type JPEG, JPG,PNG
            </div>
          </div>
          <div className="w-[20%]"></div>
          <div className={`w-[50%]`}>
            <FormItem
              name="upload"
              initialValue={updtVisFlag ? [fileList] : []}
              valuePropName="fileList"
              getValueFromEvent={(event) => {
                return event?.fileList
              }}
              rules={[
                { required: true, message: "Please upload a image" },
                {
                  validator(_, fileList) {
                    return new Promise((resolve, reject) => {
                      let isImg
                      if (fileList.length != 0) {
                        isImg =
                          fileList[0].type === "image/jpeg" ||
                          fileList[0].type === "image/jpg" ||
                          fileList[0].type === "image/png"
                        if (!isImg)
                          reject("Image type should be JPEG, JPG, PNG")
                      }
                      if (fileList.length != 0 && fileList[0].size > 3000000) {
                        reject("Image size exceed")
                      } else {
                        resolve("Success")
                      }
                    })
                  },
                },
              ]}
            >
              <Dragger onChange={handleChange} accept="image" maxCount={1}>
                {fileList.url == "" ? (
                  <div>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                  </div>
                ) : (
                  <div>
                    <img className=" w-44" src={previewImage}></img>
                  </div>
                )}
              </Dragger>
            </FormItem>
          </div>
        </div>
      </Form>
      <div className="flex mt-8 w-[100%] justify-center items-center gap-6">
        <FormItem noStyle>
          <ButtonComponent
            buttonLabel="Confirm"
            buttonOnClick={() => undefined}
            buttonBoolVal={categoryModal}
            form="ParentCategoryPopup"
            htmlType={"submit"}
            style={
              "h-[55px] w-[130px] text-lg bg-[#5ec5ff] text-white font-bold"
            }
          />
        </FormItem>
        <FormItem noStyle>
          <ButtonComponent
            buttonLabel="Cancel"
            htmlType={"button"}
            buttonOnClick={cancelButtonOnClick}
            buttonBoolVal={categoryModal}
            style={"h-[55px] w-[130px] text-lg text-black font-bold"}
          />
        </FormItem>
      </div>
    </div>
  )
}

export default PopUpAddUpdateParentCategory
