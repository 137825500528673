import React from "react";

function Header() {
  return (
    <div className="w-full bg-black h-11 text-xl flex items-center text-white ">
      <div className="w-[87%] flex  px-4 py-2">
        <div className="w-[30%]">
          <label>Product</label>
        </div>
        <div className="w-[25%]">
          <label>Variant</label>
        </div>
        <div className="w-[35%] ">
          <label>Price</label>
        </div>
        <div className="w-[10%]">
          <label>Qty</label>
        </div>
      </div>
      <div className="w-[13%]"></div>
    </div>
  );
}

export default Header;
