import { useState, useEffect } from "react"
import { Divider } from "antd"
import ParentCategoryCard from "./component/ParentCategoryCard"
import ChildCategoryCard from "./component/ChildCategoryCard"
import PopUpAddUpdateChildCategory from "./component/PopUpAddUpdateChildCategory"
import PopUpAddUpdateParentCategory from "./component/PopUpAddUpdateParentCategory"
import CardRowSkeleton from "./component/CardRowSkeleton"
import {
  CategoryIcon as imgCatg,
  DefaultImage as imgPrev,
} from "../../../assets"
import { ButtonComponent, Modal, DeleteConfirmation } from "../../../components"
import {
  useGetParentCategoryQuery,
  useGetChildCategoryMutation,
} from "../../../features/Category/api"
import {
  categoryAPIDefaultData,
  selectedParentDefaultData,
  selectedChildDefaultData,
  skeletonParentCategory,
} from "./constant/CategoryConstant"
import { ProductCategory } from "../../../features/Category/types"
import {
  SelectedChildCategory,
  SelectedParentCategory,
} from "./type/CategoryType"

const Category = () => {
  const { data: parentDataQuery, isLoading: isLoadingParent } =
    useGetParentCategoryQuery()
  const [GetChildData] = useGetChildCategoryMutation()
  const [parentCategoryData, setParentCategoryData] = useState<
    ProductCategory[]
  >([categoryAPIDefaultData])
  const [childCategoryData, setChildCategoryData] = useState<ProductCategory[]>(
    [categoryAPIDefaultData]
  )
  const [categoryModal, setCategoryModal] = useState<boolean>(false)
  const [buttonVal, setButtonVal] = useState<string>("")
  const [selectedCategory, setSelectedCategory] =
    useState<SelectedParentCategory>(selectedParentDefaultData)
  const [selectedParentCategory, setSelectedParentCategory] =
    useState<SelectedParentCategory>(selectedParentDefaultData)
  const [selectedChildCategory, setSelectedChildCategory] =
    useState<SelectedChildCategory>(selectedChildDefaultData)

  useEffect(() => {
    if (parentDataQuery) {
      setParentCategoryData(parentDataQuery)
      setSelectedParentCategory({
        id: parentDataQuery[0].id,
        categoryName: parentDataQuery[0].name,
        imageUrl: parentDataQuery[0].image_url,
      })
    }
  }, [parentDataQuery])
  useEffect(() => {
    const fetchChildData = async (parentData: string) => {
      return await GetChildData(parentData)
    }
    if (selectedParentCategory.id != "")
      fetchChildData(selectedParentCategory.id).then((data: any) => {
        if (data.data == null)
          return setChildCategoryData([categoryAPIDefaultData])
        setChildCategoryData(data.data)
      })
  }, [selectedParentCategory, categoryModal])

  const SelectedCategoryHandler = (data: {
    id: string
    categoryName: string
    imageUrl: string
  }) => {
    setSelectedCategory(data)
    if (data.imageUrl != "") setSelectedParentCategory(data)
    if (data.imageUrl == "") setSelectedChildCategory(data)
  }

  const SelectedMenuHandler = (type: string) => {
    setButtonVal(type)
    setCategoryModal(!categoryModal)
  }

  const ScreenPopUpHandler = (buttonVal: string) => {
    if (buttonVal == "deleteParent" || buttonVal == "deleteChild")
      return (
        <DeleteConfirmation
          selectedItem={{
            id: selectedCategory.id,
            itemName: selectedCategory?.categoryName,
          }}
          modal={categoryModal}
          cancelButtonOnClick={setCategoryModal}
          screen={buttonVal}
        />
      )
    if (buttonVal == "Add Parent Category" || buttonVal == "updateParent")
      return (
        <PopUpAddUpdateParentCategory
          selectedItem={{
            id: selectedParentCategory.id,
            parentCategoryName: selectedParentCategory.categoryName,
            imageUrl: selectedParentCategory.imageUrl,
          }}
          cancelButtonOnClick={setCategoryModal}
          categoryModal={categoryModal}
          popupName={
            buttonVal == "updateParent" ? "Update Parent" : "Add Parent"
          }
        />
      )
    if (buttonVal == "Add Child Category" || buttonVal == "updateChild")
      return (
        <PopUpAddUpdateChildCategory
          parentData={{
            id: selectedParentCategory.id,
            parentName: selectedParentCategory.categoryName,
          }}
          selectedItem={{
            id: selectedChildCategory.id,
            childCategoryName: selectedChildCategory.categoryName,
          }}
          cancelButtonOnClick={setCategoryModal}
          categoryModal={categoryModal}
          popupName={buttonVal == "updateChild" ? "Update Child" : "Add Child"}
        />
      )
  }

  return (
    <div className="w-full h-full p-4 flex flex-col justify-start">
      {categoryModal && (
        <Modal
          modal={categoryModal}
          setModal={setCategoryModal}
          screenPopUp={ScreenPopUpHandler(buttonVal)}
        />
      )}
      <div className=" w-full h-[7%] bg-[#A0A0A0] flex items-center justify-between">
        <div className="flex items-center">
          <img src={imgCatg} className="ml-3 w-[50px]" />
          <div className="text-4xl font-bold ml-2 font-serif">Category</div>
        </div>
        <div className="flex items-center justify-end gap-5"></div>
      </div>
      <div className="flex w-full h-[900px] py-3 gap-8">
        <div className="flex flex-col w-[50%] h-full bg-slate-400 py-[24px] px-[30px] rounded-md mt-3 gap-2">
          <div className="h-[40%] bg-slate-300 p-4">
            <div className="flex justify-between items-center">
              <div className="font-serif text-2xl font-semibold">
                Parent Category List
              </div>
              <div className="flex gap-2">
                <ButtonComponent
                  buttonLabel={"Add Parent Category"}
                  buttonOnClick={setCategoryModal}
                  setButtonVal={setButtonVal}
                  buttonBoolVal={categoryModal}
                  style={
                    "h-[45px] w-[170px] text-md bg-black text-white font-bold"
                  }
                />
              </div>
            </div>
            <Divider className=" my-2 h-[3px]" />
            <div className="overflow-y-auto mt-3 gap-1 flex flex-col h-[230px] px-1">
              {isLoadingParent &&
                skeletonParentCategory.map((value) => (
                  <CardRowSkeleton key={value} />
                ))}
              {parentCategoryData[0].id != "" &&
                parentCategoryData.map((item) => (
                  <ParentCategoryCard
                    key={item.id}
                    selectedData={selectedParentCategory}
                    data={{
                      id: item.id,
                      categoryName: item.name,
                      imageUrl: item.image_url,
                    }}
                    SelectedMenuHandler={SelectedMenuHandler}
                    SelectedParentCategoryHandler={SelectedCategoryHandler}
                  />
                ))}
            </div>
          </div>
          <div className="h-[60%] bg-slate-300 p-4 ">
            <div className="flex justify-between items-center">
              <div className="font-serif text-2xl font-semibold h-[45px] flex items-center">
                <label>Child Category List</label>
              </div>
              <div className=" flex gap-2">
                <ButtonComponent
                  buttonLabel={"Add Child Category"}
                  buttonOnClick={setCategoryModal}
                  setButtonVal={setButtonVal}
                  buttonBoolVal={categoryModal}
                  style={
                    "h-[45px] w-[160px] text-md bg-black text-white font-bold"
                  }
                />
              </div>
            </div>
            <Divider className="my-2 h-[3px]" />
            <div className=" overflow-y-auto h-[400px] w-full px-1 gap-1 flex flex-col">
              {childCategoryData[0].id != "" &&
                childCategoryData.map((item) => (
                  <ChildCategoryCard
                    key={item.id}
                    SelectedChildCategoryHandler={SelectedCategoryHandler}
                    SelectedMenuHandler={SelectedMenuHandler}
                    data={{ id: item.id, childCategoryName: item.name }}
                  />
                ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-[50%] py-[24px] px-[30px] h-full rounded-md max-h-screen mt-3 gap-[2%] bg-slate-400 ">
          <div className="w-full h-full bg-slate-300 flex flex-col rounded-md p-4">
            <div className="flex justify-between items-center">
              <div className="font-serif text-2xl font-semibold h-[45px] flex items-center">
                <label>Parent Category Image Preview</label>
              </div>
            </div>
            <Divider className="my-2 h-[3px]" />
            <div className="h-full flex items-center justify-center">
              <img
                className="w-[500px] shadow-md"
                src={
                  selectedParentCategory.imageUrl != ""
                    ? selectedParentCategory.imageUrl
                    : imgPrev
                }
                alt="imgPreview"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Category
