import { baseApi } from "../../app/Redux/baseApi";
import { Endpoints } from "../../constants/EndPoints";
import { ProductBrand, Dropdown } from "./types";

export const BrandApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getBrand: builder.query<ProductBrand[], void>({
      query: () => ({
        url: Endpoints.getBrand,
        method: "GET",
      }),
      providesTags: (result, error, page) => (result ? ["ProductBrand"] : []),
      transformResponse: (response: { data: ProductBrand[] }) => {
        return response.data;
      },
    }),
    getBrandDropdown: builder.query<Dropdown[], void>({
      query: () => ({
        url: Endpoints.getBrandDropdown,
        method: "GET",
      }),
      providesTags: (result, error, page) =>
        result ? ["ProductBrandDropdown"] : [],
      transformResponse: (response: { data: Dropdown[] }) => {
        return response.data;
      },
    }),
    deleteBrand: builder.mutation<any, string>({
      query: (id: string) => ({
        url: Endpoints.deleteBrand + id,
        method: "DELETE",
      }),
      invalidatesTags: (_, error) => (!error ? ["ProductBrand"] : []),
    }),
    createBrand: builder.mutation<any, FormData>({
      query: (body) => ({
        url: Endpoints.createBrand,
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error) => (!error ? ["ProductBrand"] : []),
    }),
    updateBrand: builder.mutation<any, { id: string; body: FormData }>({
      query: ({ id, body }) => ({
        url: Endpoints.deleteBrand + id,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_, error) => (!error ? ["ProductBrand"] : []),
    }),
  }),
});

export const {
  useGetBrandQuery,
  useGetBrandDropdownQuery,
  useDeleteBrandMutation,
  useCreateBrandMutation,
  useUpdateBrandMutation,
} = BrandApi;
