import React from "react"
import { Select } from "antd"

type options = {
  value: string
  label: string
}

interface Props {
  style?: React.CSSProperties | Object
  defaultSelected?: string | undefined
  className?: string | ""
  options: options[]
  onSelect?: (value: any) => void
}

function DropdownComponent2(props: Props) {
  const { style, defaultSelected, className, options, onSelect } = props
  return (
    <Select
      defaultActiveFirstOption={true}
      defaultValue={defaultSelected}
      style={style}
      className={className}
      options={options}
      onSelect={onSelect}
    />
  )
}

export default DropdownComponent2
