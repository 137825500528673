import React from "react";
import Header from "./Header";
import Row from "./Row";

const ProductPopUpTable = () => {
  return (
    <div>
      <Header />
      <div className="w-full p-1 h-[150px] flex flex-col gap-[2px] bg-white overflow-y-auto">
        <Row />
        <Row />
        <Row />
        <Row />
      </div>
    </div>
  );
};

export default ProductPopUpTable;
