const brandData = [
  {
    brand_id: "806496cc-4102-465c-b341-05aa7af91b58",
    brand_name: "Maxpro",
    brand_image:
      "https://media-cldnry.s-nbcnews.com/image/upload/t_social_share_1024x768_scale,f_auto,q_auto:best/streams/2013/March/130326/1C6639340-google-logo.jpg",
  },
  {
    brand_id: "806496cc-4102-465c-b341-05aa7af91b51",
    brand_name: "Maxpro1",
    brand_image:
      "https://media-cldnry.s-nbcnews.com/image/upload/t_social_share_1024x768_scale,f_auto,q_auto:best/streams/2013/March/130326/1C6639340-google-logo.jpg",
  },
  {
    brand_id: "806496cc-4102-465c-b341-05aa7af91b52",
    brand_name: "Maxpro2",
    brand_image:
      "https://media-cldnry.s-nbcnews.com/image/upload/t_social_share_1024x768_scale,f_auto,q_auto:best/streams/2013/March/130326/1C6639340-google-logo.jpg",
  },
  {
    brand_id: "806496cc-4102-465c-b341-05aa7af91b53",
    brand_name: "Maxpro3",
    brand_image:
      "https://media-cldnry.s-nbcnews.com/image/upload/t_social_share_1024x768_scale,f_auto,q_auto:best/streams/2013/March/130326/1C6639340-google-logo.jpg",
  },
  {
    brand_id: "806496cc-4102-465c-b341-05aa7af91b54",
    brand_name: "Maxpro4",
    brand_image:
      "https://media-cldnry.s-nbcnews.com/image/upload/t_social_share_1024x768_scale,f_auto,q_auto:best/streams/2013/March/130326/1C6639340-google-logo.jpg",
  },
  {
    brand_id: "806496cc-4102-465c-b341-05aa7af91b55",
    brand_name: "Maxpro5",
    brand_image:
      "https://media-cldnry.s-nbcnews.com/image/upload/t_social_share_1024x768_scale,f_auto,q_auto:best/streams/2013/March/130326/1C6639340-google-logo.jpg",
  },
  {
    brand_id: "806496cc-4102-465c-b341-05aa7af91b56",
    brand_name: "Maxpro6",
    brand_image:
      "https://media-cldnry.s-nbcnews.com/image/upload/t_social_share_1024x768_scale,f_auto,q_auto:best/streams/2013/March/130326/1C6639340-google-logo.jpg",
  },
  {
    brand_id: "806496cc-4102-465c-b341-05aa7af91b571",
    brand_name: "Maxpro11",
    brand_image:
      "https://media-cldnry.s-nbcnews.com/image/upload/t_social_share_1024x768_scale,f_auto,q_auto:best/streams/2013/March/130326/1C6639340-google-logo.jpg",
  },
  {
    brand_id: "806496cc-4102-465c-b341-05aa7af91b57",
    brand_name: "Maxpro7",
    brand_image:
      "https://media-cldnry.s-nbcnews.com/image/upload/t_social_share_1024x768_scale,f_auto,q_auto:best/streams/2013/March/130326/1C6639340-google-logo.jpg",
  },
  {
    brand_id: "806496cc-4102-465c-b341-05aa7af91b59",
    brand_name: "Maxpro9",
    brand_image:
      "https://media-cldnry.s-nbcnews.com/image/upload/t_social_share_1024x768_scale,f_auto,q_auto:best/streams/2013/March/130326/1C6639340-google-logo.jpg",
  },
  {
    brand_id: "806496cc-4102-465c-b341-05aa7af91b50",
    brand_name: "Maxpro0",
    brand_image:
      "https://media-cldnry.s-nbcnews.com/image/upload/t_social_share_1024x768_scale,f_auto,q_auto:best/streams/2013/March/130326/1C6639340-google-logo.jpg",
  },
];

export default brandData;

export const dataDummyDetail = {
  listData: [
    {
      name: "Drum",
      brand: "Nike",
      desc: "pasti Nike",
      qty: "2",
      price: "Rp 100.000,00",
      url: "https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2620&q=80",
    },
    {
      name: "Band",
      brand: "Nike",
      desc: "pasti Nike",
      qty: "2",
      price: "Rp 400.000,00",
      url: "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80",
    },
    {
      name: "Pitch",
      brand: "Nike",
      desc: "pasti Nike",
      qty: "2",
      price: "Rp 200.000,00",
      url: "https://images.unsplash.com/photo-1512756290469-ec264b7fbf87?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2253&q=80",
    },
    {
      name: "Snar",
      brand: "Nike",
      desc: "pasti Nike",
      qty: "2",
      price: "Rp 400.000,00",
      url: "https://images.unsplash.com/photo-1548895342-cbe56c360e2a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=80",
    },
  ],
};
