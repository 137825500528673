import { useState, useEffect } from "react"
import { ButtonComponent } from "../../../../components"
import { Divider, Input, Form, Select } from "antd"
import { CreateIcon } from "../../../../assets"
import type { UploadFile } from "antd/es/upload/interface"
import ProductPopUpTable from "./table/ProductPopUpTable"
const FormItem = Form.Item

interface Props {
  selectedItem: any
  cancelButtonOnClick: Function
  packageModal: boolean
  popupName: string
}

function ProductPopUp(props: Props) {
  const { selectedItem, cancelButtonOnClick, packageModal, popupName } = props
  const updtVisFlag: boolean = popupName != "Add Package" ? true : false
  const defaultImageVal: any = updtVisFlag
    ? {
        url: selectedItem.brand_image,
        uid: "cec49748-45f4-4663-852e-ef74860970bd",
        name: "firstImage",
        type: "image/png",
      }
    : {
        url: "",
        uid: "",
        name: "",
        type: "",
      }
  const [autoCompleteVal, setAutocompleteVal] = useState<any>()
  const [productPopUpModal, setProductPopUpModal] = useState<boolean>(false)
  const [optionsAutoComplete, setOptionsAutoComplete] = useState<any>()
  const OnFinishSubmitHandler = (values: any) => {
    console.log(values)
  }
  const renderTitle = (title: string) => (
    <div className="flex items-center justify-between ">{title}</div>
  )
  const options = [
    {
      label: renderTitle("test"),
      value: "test",
    },
    {
      label: renderTitle("test1"),
      value: "test1",
    },
    {
      label: renderTitle("test2"),
      value: "test2",
    },
  ]

  useEffect(() => {
    setOptionsAutoComplete(options)
  }, [])

  if (autoCompleteVal == "Add Type") {
    setAutocompleteVal("AddType")
    setProductPopUpModal(!productPopUpModal)
  }
  return (
    <div className="w-[650px]">
      <div className="w-full flex justify-start items-center text-4xl font-bold font-serif">
        <img className="w-[40px]" src={CreateIcon} />
        <div className="ml-2">{popupName + " " + selectedItem.itemName}</div>
      </div>
      <Divider />
      <div className=" w-full flex flex-col p-1">
        <label className="text-left text-xl font-semibold">Product</label>
        <Select />
      </div>
      <div className=" w-full flex flex-col p-1">
        <label className="text-left text-xl font-semibold">Variant</label>
        <Select />
      </div>
      <div className="w-full py-2 px-1 flex justify-end">
        <ButtonComponent
          buttonLabel="Add Product"
          buttonOnClick={() => undefined}
          buttonBoolVal={packageModal}
          form="BrandPopup"
          htmlType={"submit"}
          style={"h-[55px] w-[170px] text-lg bg-[#5ec5ff] text-white font-bold"}
        />
      </div>
      <div className="w-full">
        <ProductPopUpTable />
        <div className="w-full  flex justify-end gap-4">
          <div className="flex gap-1 text-base items-center">
            <label className=" font-semibold">Total Price</label>
            <label>RP. 1000000</label>
          </div>
          <div className="flex gap-1 text-base items-center">
            <label className=" font-semibold">Total Products</label>
            <label>20</label>
          </div>
        </div>
      </div>
      <div className="flex mt-8 w-[100%] justify-center items-center gap-6">
        <FormItem noStyle>
          <ButtonComponent
            buttonLabel="Confirm"
            buttonOnClick={() => undefined}
            buttonBoolVal={packageModal}
            form="BrandPopup"
            htmlType={"submit"}
            style={
              "h-[45px] w-[120px] text-lg bg-[#5ec5ff] text-white font-bold"
            }
          />
        </FormItem>
        <FormItem noStyle>
          <ButtonComponent
            buttonLabel="Cancel"
            htmlType={"button"}
            buttonOnClick={cancelButtonOnClick}
            buttonBoolVal={packageModal}
            style={"h-[45px] w-[120px] text-lg text-black font-bold"}
          />
        </FormItem>
      </div>
    </div>
  )
}

export default ProductPopUp
