import { useState } from "react"
import { CKLogo, UpdateIcon, ShowDetailsIcon } from "../../../../../assets"
import { Switch, Select } from "antd"
import TableRowVariation from "./TableRowVariation"
import { DownOutlined, UpOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
// import "./ProductList.css";
const { Option } = Select
type TableDataShape = {
  id: number
  productName?: string | undefined
  image?: string | undefined
  brand?: string | undefined
  series?: string | undefined
  type?: string | undefined
  category?: string | undefined
  price?: string | undefined
  status?: boolean | undefined
  variant?: TableDataShape[] | []
}

interface Props {
  value: TableDataShape
  containerRowsHeight: number
}

const TableRow = (props: Props) => {
  const { value, containerRowsHeight } = props
  const [showVariantFlag, setShowVariantFlag] = useState<boolean>(false)
  const isVariantAvail: boolean = value.variant?.length !== 0
  const navigate = useNavigate()
  const OnSelecetManageDropdown = (value: any) => {
    if (value === "showDetails")
      navigate("/product/product-list/product-details")
    if (value === "update")
      navigate("/product/product-list/update", { state: { screen: "update" } })
  }

  return (
    <div
      className={`w-full p-4 ${
        value.id % 2 === 0 ? " bg-neutral-400" : "bg-neutral-600"
      }`}
    >
      <div
        className={
          "w-full h-[90px] flex items-center text-base font-semibold text-white"
        }
      >
        <div className="w-[27%] flex justify-start items-center h-full">
          <img src={CKLogo} className="w-[100px]" alt="ck-marching-logo" />
          <div className="ml-3">{value.productName}</div>
        </div>
        <div className="w-[11%] flex items-center justify-center h-full">
          {value.brand}
        </div>
        <div className="w-[11%] flex items-center justify-center h-full">
          {value.series}
        </div>
        <div className="w-[11%] flex items-center justify-center h-full">
          {value.type}
        </div>
        <div className="w-[11%] flex items-center justify-center h-full">
          {value.category}
        </div>
        <div className={`w-[11%] flex items-center h-full`}>
          <label className={` ${containerRowsHeight > 740 ? " ml-1" : ""}`}>
            {value.price}
          </label>
        </div>
        <div className="w-[10%] flex items-center justify-center h-full">
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            defaultChecked={value.status}
          />
        </div>
        <div className="w-[8%] flex items-center h-full">
          <Select
            style={{ width: "150px" }}
            placeholder="Manage"
            optionLabelProp="label"
            onSelect={OnSelecetManageDropdown}
          >
            <Option value="update" label="Update">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                }}
              >
                <img
                  style={{ width: "15px", cursor: "pointer" }}
                  src={UpdateIcon}
                  alt="update-icon"
                />
                <label style={{ marginBottom: "4px", cursor: "pointer" }}>
                  Update
                </label>
              </div>
            </Option>
            <Option value="showDetails" label="Show Details">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                }}
              >
                <img
                  style={{ width: "15px", cursor: "pointer" }}
                  src={ShowDetailsIcon}
                  alt="detail-icon"
                />
                <label style={{ marginBottom: "4px", cursor: "pointer" }}>
                  Show Details
                </label>
              </div>
            </Option>
          </Select>
        </div>
      </div>
      {isVariantAvail && (
        <div className="w-full bg-[#D0D0D0] rounded-[8px] py-1 px-2">
          <div className="text-base font-bold flex items-center justify-between">
            <div>Show Product Variant</div>
            <button
              className="button-variant"
              onClick={() => setShowVariantFlag(!showVariantFlag)}
            >
              {showVariantFlag === false ? <DownOutlined /> : <UpOutlined />}
            </button>
          </div>
          {showVariantFlag && (
            <div className="flex flex-col gap-1 h-[133px] overflow-auto">
              <TableRowVariation />
              <TableRowVariation />
              <TableRowVariation />
              <TableRowVariation />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default TableRow
