import React from "react"
import { BaseSelect } from "./BaseInput"

type SelectFieldProps = {
  error?: string
  label?: string
  value?: string
  helper?: string
  name: string
  className?: string
  placeholder?: string
  type?: string
  onChange?: (e: React.ChangeEvent<any>) => void
}

const SelectField = ({
  onChange,
  label,
  error,
  value,
  helper,
  name,
  type,
  className,
  placeholder,
  children,
}: React.PropsWithChildren<SelectFieldProps>) => {
  return (
    <div className="flex flex-col gap-1 mb-3">
      {label && (
        <label htmlFor={name} className="font-bold">
          {label}
        </label>
      )}
      <BaseSelect
        onChange={onChange}
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        className={`${className} ${error && "outline-red focus:outline-red"}`}
      >
        {children}
      </BaseSelect>
      {!error && helper && <p className="text-gray text-sm">{helper}</p>}
      {error && <p className="text-red text-sm capitalize-first">{error}</p>}
    </div>
  )
}
export default SelectField
