import React, { useState } from "react";
import { ThreeDotsIcon, DownIcon, UpIcon } from "../../../assets";
import PackageList from "./component/menu/PackageList";
import PackageMenuTooltip from "./component/menu/PackageMenuTooltip";

type packageMenuType = {
  packageCategory: string;
  packageNameList: { packageName: string }[];
};

interface Props {
  SelectedItemHandler?: (type: { id: string; itemName: string }) => void;
  PackageMenuTooltipHandler?: (type: string) => void;
  HandleSelectedPackage?: (packageName: string) => void;
  selectedPackage?: string | "";
  data: packageMenuType;
}

const CheckSelectedPackageInPackageCategory = (
  selectedPackage: string,
  packageList: { packageName: string }[]
) => {
  for (let i = 0; i < packageList.length; i++) {
    if (packageList[i].packageName == selectedPackage) return true;
  }
  return false;
};

function PackageCategory({
  HandleSelectedPackage,
  selectedPackage,
  data,
  PackageMenuTooltipHandler,
  SelectedItemHandler,
}: Props) {
  const [packageListExpanded, setPackageListExpanded] =
    useState<boolean>(false);
  const [categoryTooltip, setCategoryTooltip] = useState<boolean>(false);
  const PackageColorBoolean = CheckSelectedPackageInPackageCategory(
    selectedPackage ? selectedPackage : "",
    data.packageNameList
  );
  const CategoryIconHandler = () => {
    let image = ThreeDotsIcon;
    if (data.packageNameList.length != 0 && packageListExpanded) image = UpIcon;
    if (data.packageNameList.length != 0 && !packageListExpanded)
      image = DownIcon;
    return image;
  };
  return (
    <div className="flex flex-col ">
      <div
        onClick={() =>
          SelectedItemHandler && data.packageNameList.length == 0
            ? SelectedItemHandler({ id: "", itemName: data.packageCategory })
            : () => undefined
        }
        className=" flex justify-between items-center h-[56px] text-lg font-bold relative"
      >
        <div
          className={` select-none ${
            PackageColorBoolean ? "text-sky-700" : ""
          }`}
        >
          {data.packageCategory}
        </div>
        <img
          className=" select-none cursor-pointer"
          onClick={() =>
            data.packageNameList.length == 0
              ? setCategoryTooltip(!categoryTooltip)
              : setPackageListExpanded(!packageListExpanded)
          }
          src={CategoryIconHandler()}
        />
        {categoryTooltip && (
          <div
            onMouseLeave={() => setCategoryTooltip(!categoryTooltip)}
            className="absolute right-0 top-4  h-[110px] flex items-end"
          >
            <PackageMenuTooltip
              PackageMenuTooltipHandler={PackageMenuTooltipHandler}
              usedFor="Package Category"
            />
          </div>
        )}
      </div>
      {packageListExpanded &&
        data.packageNameList.map((value: { packageName: string }) => (
          <PackageList
            key={value.packageName}
            PackageMenuTooltipHandler={PackageMenuTooltipHandler}
            HandleSelectedPackage={HandleSelectedPackage}
            SelectedItemHandler={SelectedItemHandler}
            selectedPackage={selectedPackage}
            dataPackage={value.packageName}
          />
        ))}
    </div>
  );
}

export default PackageCategory;
