import React from "react";
import { Route } from "react-router-dom";
import {
  Brand,
  Category,
  Dashboard,
  ProductList,
  Package,
  ProductDetail,
  ProductAdd,
  ProductUpdate,
} from "./pages";

export const PageRoutes = (
  <React.Fragment>
    <Route index element={<Dashboard />} />
    <Route path="/product/brand" element={<Brand />} />
    <Route path="/product/category" element={<Category />} />
    <Route path="/product/product-list" element={<ProductList />} />
    <Route
      path="/product/product-list/product-details"
      element={<ProductDetail />}
    />
    <Route path="/product/product-list/add" element={<ProductAdd />} />
    <Route path="/product/product-list/update" element={<ProductUpdate />} />
    <Route path="/product/package" element={<Package />} />
  </React.Fragment>
);
