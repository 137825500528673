import { useState } from "react"
import NoImage from "../../assets/no-image.svg"

type ImageProps = {
  src?: string
  alt?: string
  className?: string
  onClick?: () => void
}

const Image = ({ src, alt, className, onClick }: ImageProps) => {
  const [loaded, setLoaded] = useState(false)

  return (
    <>
      {loaded ? null : (
        <div
          className={`${
            className ?? "rounded"
          } shrink-0 bg-gradient-to-r from-gray/10 to-gray/30`}
        ></div>
      )}
      <img
        onClick={() => {
          if (onClick !== undefined) {
            onClick()
          }
        }}
        src={src || NoImage}
        alt={alt}
        className={className + (loaded ? " block object-cover" : " hidden")}
        onLoad={() => {
          setLoaded(true)
        }}
        onError={() => {
          setLoaded(true)
        }}
      />
    </>
  )
}

export default Image
