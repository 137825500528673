import { UpdateIcon, DeleteIcon } from "../../../../assets";
import { Select } from "antd";
const { Option } = Select;

interface Props {
  SelectedMenuHandler: (type: string) => void;
  type: "Parent" | "Child";
}

const SelectMenu = ({ SelectedMenuHandler, type }: Props) => {
  return (
    <Select
      style={{ width: "150px" }}
      placeholder="Manage"
      optionLabelProp="label"
      value={"Manage"}
      onSelect={(value: any) => SelectedMenuHandler(value + type)}
    >
      <Option value="update" label="Update">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
          }}
        >
          <img style={{ width: "15px", cursor: "pointer" }} src={UpdateIcon} />
          <label style={{ marginBottom: "4px", cursor: "pointer" }}>
            Update
          </label>
        </div>
      </Option>
      <Option value="delete" label="Delete">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
          }}
        >
          <img style={{ width: "15px", cursor: "pointer" }} src={DeleteIcon} />
          <label style={{ marginBottom: "4px", cursor: "pointer" }}>
            Delete
          </label>
        </div>
      </Option>
    </Select>
  );
};

export default SelectMenu;
