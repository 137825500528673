import { BaseInput } from './BaseInput';

type TextFieldProps = {
  error?: string;
  label?: string;
  value?: string;
  helper?: string;
  name: string;
  className?: string;
  placeholder?: string;
  type?: string;
  isDisabled?: boolean
  onChange?: (e: React.ChangeEvent<any>) => void;
};

const TextField = ({
  onChange,
  label,
  error,
  value,
  helper,
  name,
  type,
  isDisabled,
  className,
  placeholder,
}: TextFieldProps) => {
  return (
    <div className='flex flex-col gap-1 w-full mb-3'>
      {label && (
        <label htmlFor={name} className='font-bold'>
          {label}
        </label>
      )}
      <BaseInput
        onChange={onChange}
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        type={type || 'text'}
        disabled={isDisabled}
        className={`${className} ${error && 'outline-red focus:outline-red'}`}
      />
      {!error && helper && <p className='text-gray text-sm'>{helper}</p>}
      {error && <p className='text-red text-sm capitalize-first'>{error}</p>}
    </div>
  );
};
export default TextField;
