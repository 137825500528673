import ButtonComponent from "./ButtonComponent"
import { useDeleteBrandMutation } from "../features/Brand/api"
import {
  useDeleteChildCategoryMutation,
  useDeleteParentCategoryMutation,
} from "../features/Category/api"
import { useDeleteTypeMutation } from "../features/Type/api"
interface Props {
  selectedItem: { id: string; itemName: string }
  cancelButtonOnClick: Function
  modal: boolean
  screen?: string | undefined
}

function DeleteConfirmation(props: Props) {
  const { modal, selectedItem, cancelButtonOnClick, screen } = props

  const [deleteBrand] = useDeleteBrandMutation()
  const [deleteChildCategory] = useDeleteChildCategoryMutation()
  const [deleteParentCategory] = useDeleteParentCategoryMutation()
  const [deleteType] = useDeleteTypeMutation()

  const ConfirmButtonOnClickHandler = () => {
    if (screen == "Brand")
      deleteBrand(selectedItem.id)
        .unwrap()
        .catch((err: any) => console.log(err))
        .finally(() => {
          cancelButtonOnClick()
        })
    if (screen == "deleteParent")
      deleteParentCategory(selectedItem.id)
        .unwrap()
        .catch((err: any) => console.log(err))
        .finally(() => {
          cancelButtonOnClick()
        })
    if (screen == "deleteChild")
      deleteChildCategory(selectedItem.id)
        .unwrap()
        .catch((err: any) => console.log(err))
        .finally(() => {
          cancelButtonOnClick()
        })
    if (screen == "Type")
      deleteType(selectedItem.id)
        .unwrap()
        .catch((err: any) => console.log(err))
        .finally(() => {
          cancelButtonOnClick()
        })
  }
  return (
    <div className="w-[400px] p-3 flex flex-col justify-center items-center">
      <div>Are you sure want to delete {selectedItem.itemName}?</div>
      <div className="flex mt-8 w-[100%] justify-center items-center gap-6">
        <ButtonComponent
          buttonLabel="Confirm"
          buttonOnClick={ConfirmButtonOnClickHandler}
          buttonBoolVal={modal}
          style={"h-[55px] w-[130px] text-lg bg-[#5ec5ff] text-white font-bold"}
        />
        <ButtonComponent
          buttonLabel="Cancel"
          buttonOnClick={cancelButtonOnClick}
          buttonBoolVal={modal}
          style={"h-[55px] w-[130px] text-lg text-black font-bold"}
        />
      </div>
    </div>
  )
}

export default DeleteConfirmation
