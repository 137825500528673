import { useState } from "react";
import { Divider, Input, Form } from "antd";
import { ButtonComponent } from "../../../../components";
import { CreateIcon } from "../../../../assets";
const FormItem = Form.Item;

interface Props {
  cancelButtonOnClick: Function;
  typeModal: boolean;
}

const PopUpAddType = (props: Props) => {
  const { cancelButtonOnClick, typeModal } = props;

  const OnFinishSubmitHandler = (values: any) => {
    console.log(values);
  };

  return (
    <div className="w-[1000px]">
      <div className="w-full h-[10%] flex justify-start items-center text-4xl font-bold font-serif">
        <img className="w-[40px]" src={CreateIcon} />
        <div className="ml-2">Add Category</div>
      </div>
      <Divider />
      <Form
        onFinish={OnFinishSubmitHandler}
        name="TypePopUp"
        className="w-full flex items-start flex-col text-lg font-semibold"
      >
        <div className="p-4 flex w-full items-center">
          <div className="w-[20%] h-full flex justify-start pb-5">
            Category Name
          </div>
          <div className="w-[30%]"></div>
          <div className=" w-[50%]">
            <FormItem
              name="TypeName"
              rules={[{ required: true, message: "Please input Type Name" }]}
            >
              <Input size="large" placeholder="Input Category Name" />
            </FormItem>
          </div>
        </div>
      </Form>

      <div className="flex mt-8 w-[100%] justify-center items-center gap-6">
        <ButtonComponent
          buttonLabel="Confirm"
          buttonOnClick={() => undefined}
          buttonBoolVal={typeModal}
          form="TypePopUp"
          htmlType={"submit"}
          style={"h-[45px] w-[120px] text-lg bg-[#5ec5ff] text-white font-bold"}
        />
        <ButtonComponent
          buttonLabel="Cancel"
          buttonOnClick={cancelButtonOnClick}
          buttonBoolVal={typeModal}
          htmlType={"button"}
          style={"h-[45px] w-[120px] text-lg text-black font-bold"}
        />
      </div>
    </div>
  );
};

export default PopUpAddType;
