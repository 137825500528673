import { useState, useEffect } from "react"
import {
  ButtonComponent,
  AutocompleteComponent,
  Modal,
  DeleteConfirmation,
} from "../../../../../components"
import ProductImageCard from "./ProductImageCard"
import { CreateIcon, UpdateIcon } from "../../../../../assets"
import { useNavigate, useLocation } from "react-router-dom"
import { InboxOutlined } from "@ant-design/icons"
import { Divider, Input, Upload, Form, Select } from "antd"
import type { UploadFile } from "antd/es/upload/interface"
import type { UploadProps } from "antd/es/upload"
import PopUpAddType from "../PopUpAddType"
import { useGetBrandDropdownQuery } from "../../../../../features/Brand/api"
import { useGetCategoryDropdownQuery } from "../../../../../features/Category/api"
import { useGetTypeDropdownQuery } from "../../../../../features/Type/api"
import ProductAddModifyForm from "../../../AddProduct/components/ProductAddModifyForm"
import { useFormik } from "formik"
import {
  AddProductInitialValue,
  AddProductSchema,
  AddProductVariationInitialValue,
} from "../../../../../features/Product/types"
import { Headline } from "../../../../../components/Text"
import {
  OutlinedButton,
  PrimaryButton,
} from "../../../../../components/Common/Button"

const FormItem = Form.Item
const { Dragger } = Upload
const { TextArea } = Input
function AddUpdate() {
  const { data: brandDataDropdown } = useGetBrandDropdownQuery()
  const { data: categoryDataDropdown } = useGetCategoryDropdownQuery()
  const { data: typeDataDropdown } = useGetTypeDropdownQuery()

  const navigate = useNavigate()
  const { state } = useLocation()
  const { screen } = state

  const [productPopUpModal, setProductPopUpModal] = useState<boolean>(false)
  const [selectedType, setSelectedType] = useState<{
    id: string
    itemName: string
  }>({
    id: "",
    itemName: "",
  })
  const [autoCompleteVal, setAutocompleteVal] = useState<any>()
  const [optionsAutoComplete, setOptionsAutoComplete] = useState<any>()
  const [deleteImgList, setDeleteImgList] = useState<string[]>([])
  const updtVisFlag: boolean = screen === "update" ? true : false
  const defaultImageVal: any = updtVisFlag
    ? {
        url: "", //selectedItem.image_url,
        uid: "cec49748-45f4-4663-852e-ef74860970bd",
        name: "firstImage",
        type: "image/png",
      }
    : {
        url: "",
        uid: "",
        name: "",
        type: "",
      }
  const [fileList, setFileList] = useState<UploadFile[]>([defaultImageVal])
  const [previewImage, setPreviewImage] = useState<
    { id: string; imgUrl: string; imgObj: any }[]
  >([{ id: "", imgUrl: "", imgObj: "" }])

  const renderTitle = (title: string) => (
    <div className="flex items-center justify-between ">
      {title}
      <ButtonComponent
        buttonLabel="Delete"
        buttonOnClick={setProductPopUpModal}
        buttonBoolVal={productPopUpModal}
        style={"bg-[#fc9482] text-white font-bold"}
      />
    </div>
  )

  useEffect(() => {
    let newTypeDropdownData: any = []
    if (typeDataDropdown)
      for (let i = 0; i < typeDataDropdown.length; i++) {
        newTypeDropdownData.push({
          label: renderTitle(typeDataDropdown[i].label),
          value: typeDataDropdown[i].label,
          key: typeDataDropdown[i].value,
        })
      }
    setOptionsAutoComplete(newTypeDropdownData)
  }, [typeDataDropdown])

  if (autoCompleteVal === "Add Type") {
    setAutocompleteVal("AddType")
    setProductPopUpModal(!productPopUpModal)
  }
  const OnFinishSubmitHandler = (values: any) => {
    console.log(values)
  }

  const handleChange: UploadProps["onChange"] = (info: any) => {
    const imgPrevArr: { id: string; imgUrl: string; imgObj: any }[] = []

    let fileList = info.fileList

    console.log("filelistonchange: ", fileList)

    if (deleteImgList.length !== 0)
      for (let i = 0; i < deleteImgList.length; i++) {
        fileList = fileList.filter((val: any) => {
          return val.uid !== deleteImgList[i]
        })
      }

    console.log("filelistonchange deleted: ", fileList)
    for (let i = 0; i < fileList.length; i++) {
      const isImg =
        fileList[i].type === "image/jpeg" ||
        fileList[i].type === "image/jpg" ||
        fileList[i].type === "image/png"
      if (!isImg) return
      if (fileList[i].size > 3000000) {
        return
      }

      imgPrevArr.push({
        id: fileList[i].uid,
        imgUrl: URL.createObjectURL(fileList[i].originFileObj),
        imgObj: fileList[i].originFileObj,
      })
    }

    setPreviewImage(imgPrevArr)
    setFileList(fileList)
  }

  const ImageDeleteHandler = (id: string) => {
    console.log(fileList)
    setDeleteImgList([...deleteImgList, id])
    const newFileArrayPrevImage = previewImage.filter((val: any) => {
      return id !== val.id
    })
    const newFileArray = fileList.filter((val: any) => {
      return val.uid !== id
    })
    setPreviewImage(newFileArrayPrevImage)
    setFileList(
      newFileArray.length === 0
        ? [
            {
              url: "",
              uid: "",
              name: "",
              type: "",
            },
          ]
        : newFileArray
    )
  }
  // console.log("filelist: ", fileList);
  // console.log("preview image: ", previewImage);
  const [error, setError] = useState("")
  const formik = useFormik({
    initialValues: AddProductInitialValue,
    validationSchema: AddProductSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      setError("")
      const payload = new FormData()
      payload.append("category_id", values.category_id)
      payload.append("name", values.name)
      payload.append("description", values.description)
      payload.append("price", values.price.toString())
      payload.append("status", values.status)
      payload.append("specification", values.specification)
      payload.append("short_description", values.sort_description)
      payload.append("sku", values.sku)
      payload.append("brand_id", values.brand_id)
      for (let value of values.variations) {
        payload.append("variations", JSON.stringify(value))
      }
      for (let value of values.product_medias) {
        payload.append("product_medias", value)
      }

      // addProduct(payload)
      //   .unwrap()
      //   .then(() => {
      //     setOpen(false)
      //   })
      //   .catch((err) => {
      //     let myError = err as RTKQueryError
      //     if ("data" in myError) {
      //       setError(myError.data.msg)
      //     } else {
      //       setError(myError.status)
      //     }
      //   })
    },
  })

  return (
    // <div className="p-4">
    //   {productPopUpModal && (
    //     <Modal
    //       setModal={setProductPopUpModal}
    //       modal={productPopUpModal}
    //       classNameOverlay="overlay-modal-popup"
    //       classNameContent="modal-content-popup"
    //       classNameModal="modal-popup"
    //       screenPopUp={
    //         autoCompleteVal == "AddType" || autoCompleteVal == "Add Type" ? (
    //           <PopUpAddType
    //             cancelButtonOnClick={setProductPopUpModal}
    //             typeModal={productPopUpModal}
    //           />
    //         ) : (
    //           <DeleteConfirmation
    //             screen="Type"
    //             selectedItem={{
    //               id: autoCompleteVal.key,
    //               itemName: autoCompleteVal.value,
    //             }}
    //             modal={productPopUpModal}
    //             cancelButtonOnClick={setProductPopUpModal}
    //           />
    //         )
    //       }
    //     />
    //   )}
    //   <div className="w-full h-[5%] flex items-center justify-between text-4xl font-bold font-serif">
    //     <div className="flex justify-start items-center">
    //       <img
    //         className="w-[40px]"
    //         src={screen == "update" ? UpdateIcon : CreateIcon}
    //       />
    //       <div className="ml-2">
    //         {screen == "update" ? "Update" : "Add"} Product
    //       </div>
    //     </div>
    //     <ButtonComponent
    //       buttonLabel="Back"
    //       htmlType={"button"}
    //       buttonOnClick={() => navigate("/product/product-list")}
    //       style={"h-[55px] w-[130px] text-lg bg-[grey] text-white font-bold"}
    //     />
    //   </div>
    //   <Divider className=" my-3" />
    //   <Form
    //     name="ProductPopup"
    //     onFinish={OnFinishSubmitHandler}
    //     className="w-full h-[940px] py-2 px-1 overflow-y-auto flex flex-wrap gap-3"
    //   >
    //     <div className="w-full shadow-md shadow-neutral-600 rounded-xl flex items-start flex-col text-lg font-semibold">
    //       <div className="p-4 flex w-full items-center">
    //         <div className="w-[20%] h-full flex justify-start">
    //           Product Name
    //         </div>
    //         <div className="w-[30%]"></div>
    //         <div className=" w-[50%]">
    //           <FormItem
    //             name="ProductName"
    //             rules={[
    //               { required: true, message: "Please input Product Name" },
    //             ]}
    //           >
    //             <Input size="large" placeholder="Input Product Name" />
    //           </FormItem>
    //         </div>
    //       </div>
    //       <div className="p-4 flex w-full items-center">
    //         <div className="w-[20%] h-full flex justify-start">Brand</div>
    //         <div className="w-[30%]"></div>
    //         <div className=" w-[50%]">
    //           <FormItem
    //             name="BrandName"
    //             rules={[
    //               { required: true, message: "Please select Brand Name" },
    //             ]}
    //           >
    //             <Select
    //               style={{ width: "100%", textAlign: "initial" }}
    //               options={brandDataDropdown ? brandDataDropdown : []}
    //             />
    //           </FormItem>
    //         </div>
    //       </div>
    //       <div className="p-4 flex w-full items-center">
    //         <div className="w-[20%] h-full flex justify-start">Type</div>
    //         <div className="w-[30%]"></div>
    //         <div className=" w-[50%]">
    //           <FormItem
    //             name="TypeName"
    //             rules={[{ required: true, message: "Please input Type Name" }]}
    //           >
    //             <AutocompleteComponent
    //               placeholder={"Input Type Name"}
    //               options={optionsAutoComplete}
    //               onSelect={setAutocompleteVal}
    //               notFoundContentOnSelectVal={setAutocompleteVal}
    //               notFoundContent="Add Type"
    //               notFoundContentModal={productPopUpModal}
    //               style={{
    //                 width: "258px",
    //                 display: "flex",
    //                 justifyContent: "start",
    //               }}
    //             />
    //           </FormItem>
    //         </div>
    //       </div>
    //       {/* <div className="p-4 flex w-full items-center">
    //         <div className="w-[20%] h-full flex justify-start">Series</div>
    //         <div className="w-[30%]"></div>
    //         <div className=" w-[50%]">
    //           <FormItem
    //             name="SeriesName"
    //             rules={[
    //               { required: true, message: "Please select Series Name" },
    //             ]}
    //           >
    //             <DropdownComponent2
    //               defaultSelected=""
    //               style={{ width: "100%", textAlign: "initial" }}
    //               options={[
    //                 { value: "Brand1", label: "Brand1" },
    //                 { value: "Brand2", label: "Brand2" },
    //               ]}
    //             />
    //           </FormItem>
    //         </div>
    //       </div> */}
    //       <div className="p-4 flex w-full items-center">
    //         <div className="w-[20%] h-full flex justify-start">Category</div>
    //         <div className="w-[30%]"></div>
    //         <div className=" w-[50%]">
    //           <FormItem
    //             name="CategoryName"
    //             rules={[
    //               { required: true, message: "Please select Category Name" },
    //             ]}
    //           >
    //             <Select
    //               style={{ width: "100%", textAlign: "initial" }}
    //               options={categoryDataDropdown ? categoryDataDropdown : []}
    //             />
    //           </FormItem>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="w-full shadow-md shadow-neutral-600 rounded-xl flex items-start flex-col text-lg font-semibold">
    //       <div className="p-4 flex w-full items-center">
    //         <div className="w-[20%] h-full flex justify-start">
    //           Product Pictures
    //         </div>
    //         <div className="w-[30%]"></div>
    //         <div className=" w-[50%]">
    //           <FormItem
    //             name="upload"
    //             initialValue={updtVisFlag ? [fileList] : []}
    //             valuePropName="fileList"
    //             getValueFromEvent={(event) => {
    //               return event?.fileList
    //             }}
    //             rules={[
    //               { required: true, message: "Please upload a image" },
    //               // {
    //               //   validator(_, fileList) {
    //               //     return new Promise((resolve, reject) => {
    //               //       let isImg;
    //               //       if (fileList.length != 0) {
    //               //         isImg =
    //               //           fileList[0].type === "image/jpeg" ||
    //               //           fileList[0].type === "image/jpg" ||
    //               //           fileList[0].type === "image/png";
    //               //         if (!isImg)
    //               //           reject("Image type should be JPEG, JPG, PNG");
    //               //       }
    //               //       if (
    //               //         fileList.length != 0 &&
    //               //         fileList[0].size > 3000000
    //               //       ) {
    //               //         reject("Image size exceed");
    //               //       } else {
    //               //         resolve("Success");
    //               //       }
    //               //     });
    //               //   },
    //               // },
    //             ]}
    //           >
    //             <Dragger
    //               onChange={handleChange}
    //               accept="image"
    //               action={""}
    //               multiple={true}
    //             >
    //               {fileList[0].url == "" ? (
    //                 <div>
    //                   <p className="ant-upload-drag-icon">
    //                     <InboxOutlined />
    //                   </p>
    //                   <p className="ant-upload-text">
    //                     Click or drag file to this area to upload
    //                   </p>
    //                 </div>
    //               ) : (
    //                 <div className="flex items-center gap-2 flex-wrap p-2">
    //                   {previewImage.map((val: any, index: number) => (
    //                     <ProductImageCard
    //                       imgVal={val}
    //                       key={index}
    //                       onDeleteHandler={ImageDeleteHandler}
    //                     />
    //                   ))}
    //                 </div>
    //               )}
    //             </Dragger>
    //           </FormItem>
    //         </div>
    //       </div>
    //       <div className="p-4 flex w-full items-center">
    //         <div className="w-[20%] h-full flex justify-start">Description</div>
    //         <div className="w-[30%]"></div>
    //         <div className=" w-[50%]">
    //           <TextArea rows={4} />
    //         </div>
    //       </div>
    //       <div className="p-4 flex w-full items-center">
    //         <div className="w-[20%] h-full flex justify-start">
    //           Specification
    //         </div>
    //         <div className="w-[30%]"></div>
    //         <div className=" w-[50%]">
    //           <TextArea rows={4} />
    //         </div>
    //       </div>
    //     </div>
    //     <div className="w-full shadow-md shadow-neutral-600 rounded-xl flex items-start flex-col text-lg font-semibold">
    //       <div className="p-4 flex w-full items-center">
    //         <div className="w-[20%] h-full flex justify-start">
    //           Variant Pictures
    //         </div>
    //         <div className="w-[30%]"></div>
    //         <div className=" w-[50%]">
    //           <button>tye</button>
    //         </div>
    //       </div>
    //     </div>
    //   </Form>
    //   <div className="flex mt-3 h-[5%] w-[100%] justify-center items-center gap-6">
    //     <ButtonComponent
    //       buttonLabel="Submit"
    //       form="ProductPopup"
    //       htmlType={"submit"}
    //       buttonOnClick={() => undefined}
    //       style={"h-[45px] w-[120px] text-lg bg-[#5ec5ff] text-white font-bold"}
    //     />
    //   </div>
    // </div>
    <form
      onSubmit={formik.handleSubmit}
      className="flex flex-col gap-4 w-full px-10 mt-5 overflow-y-auto"
    >
      <div
        className="h-screen w-full overflow-y-auto
      "
      >
        <ProductAddModifyForm
          errors={formik.errors}
          touched={formik.touched}
          values={formik.values}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          setFieldValue={formik.setFieldValue}
          isCreate={true}
          setErrors={formik.setErrors}
        />

        <Headline className="text-red">{error}</Headline>
        <div className="grid grid-cols-2 p-2">
          <div className="mx-2">
            <PrimaryButton type="submit">
              {/* {isLoading ? <CircularProgressIcon /> : 'Submit Product'} */}
            </PrimaryButton>
          </div>
          <div className="mx-2">
            <OutlinedButton>Cancel</OutlinedButton>
          </div>
        </div>
      </div>
    </form>
  )
}

export default AddUpdate
