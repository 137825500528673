import { BaseInputWithoutRadius } from './BaseInput';

type TextFieldWithIconProps = {
  error?: string;
  label?: string;
  value?: string;
  helper?: string;
  disabled?: boolean;
  name: string;
  className?: string;
  placeholder?: string;
  type?: string;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  onChange?: (e: React.ChangeEvent<any>) => void;
};

const TextFieldWithIcon = ({
  onChange,
  disabled,
  error,
  value,
  helper,
  name,
  type,
  className,
  placeholder,
  leftElement,
  rightElement,
}: TextFieldWithIconProps) => {
  let roundedStyle = ''
  
  if(!leftElement) {
    roundedStyle += 'rounded-tl rounded-bl'
  }
  if(!rightElement) {
    roundedStyle += 'rounded-tr rounded-br'
  }

  return (
    <div className='flex flex-col gap-1 w-full'>
        <div className='flex flex-row w-full items-center'>
            {leftElement && <div className='p-2.5 rounded-tl rounded-bl bg-gray-lighter font-bold'>
              {leftElement}
            </div>}
            <BaseInputWithoutRadius
                onChange={onChange}
                id={name}
                name={name}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                type={type || 'text'}
                className={`${className} ${roundedStyle} ${error && 'outline-red focus:outline-red'} ${disabled && 'bg-gray-lighter'}`}
            />
            {rightElement && <div className='p-2.5 rounded-tr rounded-br bg-gray-lighter font-bold'>
              {rightElement}
            </div>}
        </div>

        {!error && helper && <p className='text-gray text-sm'>{helper}</p>}
        {error && <p className='text-red text-sm capitalize-first'>{error}</p>}
    </div>
  );
};
export default TextFieldWithIcon;
