import { useState } from "react"
import { dataDummyDetail } from "."
import { dataDescription } from "."
import { Divider } from "antd"
import { ShowDetailsLargerIcon } from "../../../assets"
import { useNavigate } from "react-router-dom"
import { ButtonComponent, TabComponent } from "../../../components"
const ProductDetail = () => {
  const [showTab, setShowTab] = useState(1)
  const navigate = useNavigate()
  const handletab = (e: number) => {
    setShowTab(e)
  }

  return (
    <div className=" p-4">
      <div className="w-full h-[5%] flex items-center justify-between text-4xl font-bold font-serif">
        <div className="flex justify-start items-center">
          <img className="w-[40px]" src={ShowDetailsLargerIcon} />
          <div className="ml-2">Product Details</div>
        </div>
        <ButtonComponent
          buttonLabel="Back"
          htmlType={"button"}
          buttonOnClick={() => navigate("/product/product-list")}
          style={"h-[55px] w-[130px] text-lg bg-[grey] text-white font-bold"}
        />
      </div>
      <Divider className=" my-3" />
      <div className="flex flex-row mb-[30px]">
        <div className="flex flex-col">
          <div className="w-[250px] h-[178px] bg-slate-200"></div>
          <div className="w-[250px] h-[178px] my-[8px] bg-slate-200"></div>
          <div className="w-[250px] h-[178px] bg-slate-200"></div>
        </div>
        <div className="mx-[40px]">
          <div className="w-[550px] h-[550px] bg-slate-200"></div>
        </div>
        <div className="flex flex-col items-start">
          <div className="flex flex-col items-start">
            <div className="text-3xl font-bold">Product Name</div>
            <div className=" text-2xl font-bold py-5">Brand/Series</div>
            <div className="text-3xl font-bold">Rp 1.000.000,00</div>
          </div>
          <div className="my-[20px]">
            <h1 className="text-xl flex">Size:</h1>
            <div className="flex flex-row my-[26px] px-3">
              <div className="font-bold w-[80px] h-[50px] rounded drop-shadow-lg bg-white hover:bg-slate-200 cursor-pointer text-center p-[12px] mr-[25px]">
                10 IN
              </div>
              <div className="font-bold w-[80px] h-[50px] rounded drop-shadow-lg bg-white hover:bg-slate-200 cursor-pointer text-center p-[12px] mr-[25px]">
                11 IN
              </div>
              <div className="font-bold w-[80px] h-[50px] rounded drop-shadow-lg bg-white hover:bg-slate-200 cursor-pointer text-center p-[12px] mr-[25px]">
                12 IN
              </div>
              <div className="font-bold w-[80px] h-[50px] rounded drop-shadow-lg bg-white hover:bg-slate-200 cursor-pointer text-center p-[12px] mr-[25px]">
                13 IN
              </div>
            </div>
          </div>
          <div>
            <div className=" text-left text-sm p-0">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="relative">
          <div>
            <TabComponent
              labelClassName=" text-3xl font-bold"
              items={[
                {
                  key: "Description",
                },
                {
                  key: "Specification",
                },
              ]}
            />
          </div>
        </div>
        {dataDescription.listData.map((item: any) => (
          <div className="w-full text-sm">
            {showTab === 1 ? (
              <div className="text-left">{item.desc}</div>
            ) : null}
            {showTab === 2 ? (
              <div className="text-left">{item.spec}</div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProductDetail
