import * as Yup from "yup"
import { ProductCategory } from "../Category/types"
import QueryParams from "../../constants/QueryParam"
import { ProductBrand } from "../Brand/types"

export enum ProductStatus {
  Active = "active",
  Inactive = "inactive",
}

export type ProductVariation = {
  id: string
  variation_name: string
  variation_selection: string
  variation_price: number
  variation_status: boolean
}

export type ProductMedia = {
  id: string
  url: string
  media_type: "image" | "video"
}

export type Product = {
  id: string
  name: string
  price: number
  category: ProductCategory
  specification: string
  sort_description: string
  sku: string
  status: string
  product_media: ProductMedia[]
  variations: ProductVariation[]
}

interface CommonProductForm {
  category_id: string
  brand_id: string
  name: string
  description: string
  specification: string
  sort_description: string
  sku: string
  price: number
  status: string
  product_medias: File[]
}

export interface AddProductForm extends CommonProductForm {
  variations: AddProductVariationForm[]
}

export interface AddProductVariationForm {
  variation_name: string
  variation_selection: string
  variation_price: number
  variation_status: boolean
  variation_sku: string
}

export const AddProductInitialValue: AddProductForm = {
  category_id: "",
  brand_id: "",
  name: "",
  description: "",
  sort_description: "",
  sku: "",
  specification: "",
  price: 1,
  status: ProductStatus.Active,
  variations: [],
  product_medias: [],
}

export const AddProductVariationInitialValue: AddProductVariationForm = {
  variation_name: "",
  variation_selection: "",
  variation_sku: "",
  variation_status: false,
  variation_price: 0,
}

const CommonProductSchema = {
  brand_id: Yup.string().required("Brand is required"),
  category_id: Yup.string().required("Category is required"),
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  price: Yup.number().min(1, "Price must be more than 0"),
  status: Yup.mixed<ProductStatus>()
    .oneOf(Object.values(ProductStatus))
    .required("Status is required"),
}

export const AddProductSchema = Yup.object().shape({
  ...CommonProductSchema,
  variations: Yup.array().of(
    Yup.object().shape({
      variation_name: Yup.string().required("Variation name is required"),
      variation_selection: Yup.string().required(
        "Variation selection 1 is required"
      ),
    })
  ),
})

export type ProductQueryParams = QueryParams & {
  min_rating?: string
  brand?: string
  pmin?: string
  pmax?: string
  type?: string[]
  category?: string[]
}

export type ProductDetail = {
  id: string
  name: string
  price: number
  category: ProductCategory
  brand: ProductBrand
  description: string
  specification: string
  sort_description: string
  sku: string
  variations: ShortVariant[]
  product_media: ProductMedia[]
}

export type ShortVariant = {
  id: string
  variation_name: string
  variation_selection: string
  variation_price: number
  variation_status: boolean
}

export type Success = {
  code: number
  msg: string
  data: null
}
