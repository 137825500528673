import tw from "tailwind-styled-components"

export const BaseButton = tw.button<any>`
    ${(p) => p.className}
    relative
    mt-auto
    flex
    justify-center
    items-center
    w-full
    rounded-lg
    py-2
    px-4
    transition-colors
    duration-300
    font-bold
`

export const PrimaryButton = tw(BaseButton)`
    items-center
    text-white
    bg-blue
    hover:bg-blue-darker
    text-sm 
    md:text-md
`

export const OutlinedButton = tw(BaseButton)`
    items-center
    text-sm
    text-blue
    border-blue
    border-0.5
`

export const TransparentButton = tw(BaseButton)`
    text-black
    bg-transparent
    border-gray
    border-0.5
    border-opacity-30
    focus:border-transparent
    focus:bg-gray
    focus:bg-opacity-20
`

export const GrayButton = tw(BaseButton)`
    items-center
    text-white
    bg-neutral-400
    hover:bg-neutral-700
    text-sm 
    md:text-md
`

export const PrimaryInvertButton = tw(BaseButton)`
    items-center
    text-blue
    bg-white
    hover:text-sky-600
`
