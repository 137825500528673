export const dataDummyDetail = {
  listData: [
    {
      name: "Drum",
      brand: "Nike",
      desc: "pasti Nike",
      qty: "2",
      price: "Rp 100.000,00",
      url: "https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2620&q=80",
    },
    {
      name: "Band",
      brand: "Nike",
      desc: "pasti Nike",
      qty: "2",
      price: "Rp 400.000,00",
      url: "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80",
    },
    {
      name: "Pitch",
      brand: "Nike",
      desc: "pasti Nike",
      qty: "2",
      price: "Rp 200.000,00",
      url: "https://images.unsplash.com/photo-1512756290469-ec264b7fbf87?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2253&q=80",
    },
    {
      name: "Snar",
      brand: "Nike",
      desc: "pasti Nike",
      qty: "2",
      price: "Rp 400.000,00",
      url: "https://images.unsplash.com/photo-1548895342-cbe56c360e2a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=80",
    },
    ,
    {
      name: "Snar",
      brand: "Nike",
      desc: "pasti Nike",
      qty: "2",
      price: "Rp 400.000,00",
      url: "https://images.unsplash.com/photo-1548895342-cbe56c360e2a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=80",
    },
    ,
    {
      name: "Snar",
      brand: "Nike",
      desc: "pasti Nike",
      qty: "2",
      price: "Rp 400.000,00",
      url: "https://images.unsplash.com/photo-1548895342-cbe56c360e2a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=80",
    },
    ,
    {
      name: "Snar",
      brand: "Nike",
      desc: "pasti Nike",
      qty: "2",
      price: "Rp 400.000,00",
      url: "https://images.unsplash.com/photo-1548895342-cbe56c360e2a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=80",
    },
    ,
    {
      name: "Snar",
      brand: "Nike",
      desc: "pasti Nike",
      qty: "2",
      price: "Rp 400.000,00",
      url: "https://images.unsplash.com/photo-1548895342-cbe56c360e2a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=80",
    },
    ,
    {
      name: "Snar",
      brand: "Nike",
      desc: "pasti Nike",
      qty: "2",
      price: "Rp 400.000,00",
      url: "https://images.unsplash.com/photo-1548895342-cbe56c360e2a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=80",
    },
  ],
};

export const dummyDataPackage = [
  {
    packageCategory: "Package Category 1",
    packageNameList: [
      { packageName: "Package 1" },
      { packageName: "Package 2" },
      { packageName: "Package 3" },
    ],
  },
  {
    packageCategory: "Package Category 2",
    packageNameList: [
      { packageName: "Package 4" },
      { packageName: "Package 5" },
    ],
  },
  {
    packageCategory: "Package Category 3",
    packageNameList: [{ packageName: "Package 6" }],
  },
  {
    packageCategory: "Package Category 4",
    packageNameList: [],
  },
];
