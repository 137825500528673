import SelectMenu from "./SelectMenu";

interface Props {
  data: { id: string; categoryName: string; imageUrl: string };
  SelectedParentCategoryHandler: (data: {
    id: string;
    categoryName: string;
    imageUrl: string;
  }) => void;
  selectedData: {
    id: string;
    categoryName: string;
    imageUrl: string;
  };
  SelectedMenuHandler: (type: string) => void;
}

function ParentCategoryCard({
  data,
  SelectedParentCategoryHandler,
  SelectedMenuHandler,
  selectedData,
}: Props) {
  const selectedFlag: boolean = data.id == selectedData.id ? true : false;

  return (
    <div
      onClick={() => SelectedParentCategoryHandler(data)}
      className={`w-full h-[50px]  rounded-md flex items-center p-3 justify-between hover:bg-slate-600 cursor-pointer ${
        selectedFlag ? "bg-slate-600" : "bg-slate-200"
      }`}
    >
      <h2>{data.categoryName}</h2>
      <SelectMenu SelectedMenuHandler={SelectedMenuHandler} type="Parent" />
    </div>
  );
}

export default ParentCategoryCard;
