import { BaseTextArea } from './BaseInput';

type TextAreaFieldProps = {
  error?: string;
  label?: string;
  value?: string;
  helper?: string;
  name: string;
  className?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  row?: number;
  col?: number;
};

const TextAreaField = ({
  onChange,
  label,
  error,
  value,
  helper,
  name,
  className,
  placeholder,
  row,
  col,
}: TextAreaFieldProps) => {
  return (
    <div className="flex flex-col gap-1 w-full">
      {label && (
        <label htmlFor={name} className="font">
          {label}
        </label>
      )}
      <BaseTextArea
        onChange={onChange}
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        className={`${className} ${error && 'outline-red focus:outline-red'}`}
        rows={row}
        cols={col}
      />
      {!error && helper && <p className="text-gray text-sm">{helper}</p>}
      {error && <p className="text-red text-sm capitalize-first">{error}</p>}
    </div>
  );
};

export default TextAreaField;
