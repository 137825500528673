import tw from 'tailwind-styled-components';

export const Header = tw.h1<any>`
    ${(p) => p.className}
    ${(p) => (p.bold ? 'font-bold' : '')}
    md:text-3xl
    text-xl
`;

export const Title = tw.h2<any>`
    ${(p) => (p.bold ? 'font-bold' : '')}
    md:text-xl
    text-base
`;

export const LateGrowTitle = tw.h2<any>`
    ${(p) => (p.bold ? 'font-bold' : '')}
    lg:text-xl
    text-base
`;

export const Headline = tw.h3<any>`
    ${(p) => p.className}
    ${(p) => (p.bold ? 'font-bold' : '')}
    md:text-base
    text-sm
`;

export const Body = tw.p<any>`
    ${(p) => p.className}
    ${(p) => (p.bold ? 'font-bold' : '')}
    md:text-sm
    text-xs
`;

export const LateGrowBody = tw.p<any>`
    ${(p) => p.className}
    ${(p) => (p.bold ? 'font-bold' : '')}
    lg:text-sm
    text-xs
`;

export const Caption = tw.p<any>`
    ${(p) => p.className}
    ${(p) => (p.bold ? 'font-bold' : '')}
    text-xs
`;
