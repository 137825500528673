import { useState } from "react"
import { PackageIcon } from "../../../assets"
import { Divider } from "antd"
import { dataDummyDetail as productListData } from "./DummyData"
import { dataDummyDetail } from "./DummyData"
import "./Package.css"
import PackageMenu from "./component/menu/PackageMenu"
import { Modal, DeleteConfirmation, ButtonComponent } from "../../../components"
import { PlusCircleOutlined } from "@ant-design/icons"
import PackagePopUp from "./component/PackagePopUp"
import ProductCard from "./component/ProductCard"
import ProductPopUp from "./component/ProductPopUp"

const Package = () => {
  const [packageModal, setPackageModal] = useState<boolean>(false)
  const [buttonVal, setButtonVal] = useState<string>("")
  const [selectedItem, setSelectedItem] = useState<{
    id: string
    itemName: string
  }>({
    id: "",
    itemName: "",
  })

  const SelectedItemHandler = (selectedItem: {
    id: string
    itemName: string
  }) => {
    setSelectedItem(selectedItem)
  }
  const PackageMenuTooltipHandler = (type: string) => {
    setButtonVal(type)
    setPackageModal(!packageModal)
  }

  const ScreenPopUpHandler = (buttonVal: string) => {
    if (buttonVal == "Delete")
      return (
        <DeleteConfirmation
          selectedItem={{
            id: selectedItem.id,
            itemName: selectedItem?.itemName,
          }}
          modal={packageModal}
          cancelButtonOnClick={setPackageModal}
        />
      )
    if (buttonVal == "Add Product")
      return (
        <ProductPopUp
          selectedItem={selectedItem}
          popupName={buttonVal}
          packageModal={packageModal}
          cancelButtonOnClick={setPackageModal}
        />
      )

    return (
      <PackagePopUp
        selectedItem={selectedItem}
        packageModal={packageModal}
        cancelButtonOnClick={setPackageModal}
        popupName={buttonVal}
      />
    )
  }
  console.log(selectedItem.itemName == "")
  console.log(!(selectedItem.itemName == ""))
  return (
    <div className="w-full h-full p-4 flex flex-col justify-start">
      {packageModal && (
        <Modal
          modal={packageModal}
          setModal={setPackageModal}
          screenPopUp={ScreenPopUpHandler(buttonVal)}
        />
      )}
      <div className=" w-full h-[7%] bg-[#A0A0A0] flex items-center justify-between">
        <div className="flex items-center">
          <img src={PackageIcon} />
          <div className="text-4xl font-bold ml-2 font-serif">Package</div>
        </div>
        <div className="flex items-center justify-end gap-5">
          <div className=" bg-gray-500 text-zinc-300 p-2 flex items-center justify-center rounded-md mr-2">
            <label className=" text-lg">Total Package</label>
            <div className=" bg-white p-1 rounded-md ml-2 text-black">
              {productListData.listData.length}
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full h-[1000px] py-3">
        <div className="flex flex-col w-[650px] h-full bg-slate-400 p-[24px] rounded-md mr-[24px]">
          <div className="flex justify-between items-center">
            <div className="font-serif text-xl font-semibold">Package List</div>
            <ButtonComponent
              icon={<PlusCircleOutlined />}
              buttonLabel={"Add Package"}
              style={
                "h-[55px] w-[185px] text-sm ml-[8px] bg-[#a6df98] text-white font-bold"
              }
              buttonOnClick={setPackageModal}
              setButtonVal={setButtonVal}
              buttonBoolVal={packageModal}
            />
          </div>
          <Divider className=" my-2 h-[3px]" />
          <div className=" overflow-y-auto mt-3 h-[900px]">
            <PackageMenu
              PackageMenuTooltipHandler={PackageMenuTooltipHandler}
              SelectedItemHandler={SelectedItemHandler}
            />
          </div>
        </div>
        <div className="flex flex-col w-full bg-slate-400 p-[24px] rounded-md max-h-screen ">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <label className="font-serif text-2xl font-semibold">
                Product List
              </label>
              <div className=" bg-gray-500 text-zinc-300 p-2 flex items-center justify-center rounded-md ml-2">
                <label className=" text-lg">Total Products</label>
                <div className=" bg-white p-1 rounded-md ml-2 text-black">
                  {productListData.listData.length}
                </div>
              </div>
              <div className=" bg-gray-500 text-zinc-300 p-2 flex items-center justify-center rounded-md ml-2">
                <label className=" text-lg">Total Price</label>
                <div className=" bg-white p-1 rounded-md ml-2 text-black">
                  Rp. {"20000000"}
                </div>
              </div>
            </div>
            <ButtonComponent
              icon={<PlusCircleOutlined />}
              buttonLabel={"Add Product"}
              style={
                "h-[55px] w-[185px] text-sm ml-[8px] bg-[#a6df98] text-white font-bold"
              }
              buttonOnClick={setPackageModal}
              setButtonVal={setButtonVal}
              buttonBoolVal={packageModal}
            />
          </div>
          <Divider className=" my-2 h-[3px]" />
          <div className="grid grid-cols-4 gap-y-5 justify-items-center overflow-y-auto mt-3">
            {selectedItem.itemName != "" &&
              dataDummyDetail.listData.map((item: any) => (
                <ProductCard
                  item={item}
                  DeleteProductHandler={PackageMenuTooltipHandler}
                  SelectedItemHandler={SelectedItemHandler}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Package
