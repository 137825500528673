import { useState } from "react"
import {
  TabComponent,
  PaginationComponent,
  ButtonComponent,
  Modal,
} from "../../../components"
import { ProductListIcon } from "../../../assets"
import "./ProductList.css"
import { PlusCircleOutlined } from "@ant-design/icons"
import PopUpProduct from "./component/PopUpProduct"
import TableCust from "./component/Table/TableCust"
import FilterComponent from "./component/FilterComponent"
import productListData from "./DummyData"
import { useNavigate } from "react-router-dom"
import { TabComponentConstant } from "./constant/ProductListConstant"

const ProductList = () => {
  const [productModal, setProductModal] = useState<boolean>(false)
  const [filterValue, setFilterValue] = useState<{
    status: string
    searchProduct: string
    brand: string
    category: string
    type: string
    page: number
  }>({
    status: "All Products",
    searchProduct: "",
    brand: "All",
    category: "All",
    type: "All",
    page: 1,
  })

  const FormDataHandler = (type: string, value: any) => {
    if (type === "status") setFilterValue({ ...filterValue, status: value })
    if (type === "searchProduct")
      setFilterValue({ ...filterValue, searchProduct: value })
    if (type === "brand") setFilterValue({ ...filterValue, brand: value })
    if (type === "category") setFilterValue({ ...filterValue, category: value })
    if (type === "type") setFilterValue({ ...filterValue, type: value })
    if (type === "page") setFilterValue({ ...filterValue, page: value })
  }

  const navigate = useNavigate()

  console.log(filterValue)
  return (
    <div className="w-full h-full p-4 flex flex-col justify-start">
      {productModal && (
        <Modal
          modal={productModal}
          setModal={setProductModal}
          screenPopUp={
            <PopUpProduct
              productModal={productModal}
              cancelButtonOnClick={setProductModal}
            />
          }
        />
      )}

      <div className=" w-full h-[7%] bg-[#A0A0A0] flex items-center justify-between">
        <div className="flex items-center">
          <img src={ProductListIcon} alt="product-list-icon" />
          <div className="text-4xl font-bold ml-2 font-serif">
            Products List
          </div>
        </div>
        <div className="flex items-center justify-end gap-5">
          <div className=" bg-gray-500 text-zinc-300 p-2 flex items-center justify-center rounded-md">
            <label className=" text-lg">Total Products</label>
            <div className=" bg-white p-1 rounded-md ml-2 text-black">
              {productListData.length}
            </div>
          </div>
          <div className=" bg-gray-500 text-zinc-300 p-2 flex items-center justify-center rounded-md">
            <label className=" text-lg">Active Products</label>
            <div className=" bg-white p-1 rounded-md ml-2 text-black">
              {productListData.length}
            </div>
          </div>
          <div className=" bg-gray-500 text-zinc-300 p-2 mr-2 flex items-center justify-center rounded-md">
            <label className=" text-lg">InActive Products</label>
            <div className=" bg-white p-1 rounded-md ml-2 text-black">
              {productListData.length}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[5%] flex items-center justify-between">
        <div className="w-[350px] h-full">
          <TabComponent
            items={TabComponentConstant}
            onChange={(value: any) => FormDataHandler("status", value)}
          />
        </div>
        <div>
          <ButtonComponent
            icon={<PlusCircleOutlined />}
            buttonLabel={"Add Product"}
            style={
              "h-[50px] w-[200px] text-base bg-[#73d9e2] text-white font-bold"
            }
            buttonOnClick={() =>
              navigate("/product/product-list/add", {
                state: { screen: "add" },
              })
            }
          />
        </div>
      </div>
      <div className="w-full h-[7%] flex">
        <FilterComponent FormDataHandler={FormDataHandler} />
      </div>
      <div className="w-full h-[76%] pt-2">
        <TableCust />
      </div>
      <div className="w-full h-[5%]  flex items-center justify-center">
        <PaginationComponent
          count={3}
          setPage={(value: any) => FormDataHandler("page", value)}
        />
      </div>
    </div>
  )
}

export default ProductList
