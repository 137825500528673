import React from "react"
import { AuthLayout, GenericLayout } from "./pages"
import { PageRoutes } from "./routes"
import "./App.css"
import { Routes, Route } from "react-router-dom"

function App() {
  return (
    <Routes>
      <Route path="/" element={<GenericLayout />}>
        {PageRoutes}
      </Route>
    </Routes>
  )
}

export default App
