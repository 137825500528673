import { useState, useEffect } from "react";
import { DropdownComponent2, SearchComponent } from "../../../../components";
import { dropdownStateType } from "../type/ComponentType";
import { dropdownDefaultValue } from "../constant/ProductListConstant";
import { useGetBrandDropdownQuery } from "../../../../features/Brand/api";
import { useGetCategoryDropdownQuery } from "../../../../features/Category/api";
import { useGetTypeDropdownQuery } from "../../../../features/Type/api";

interface Props {
  FormDataHandler: (type: string, value: number | string) => void;
}

const FilterComponent = ({ FormDataHandler }: Props) => {
  const { data: brandDataDropdown } = useGetBrandDropdownQuery();
  const { data: categoryDataDropdown } = useGetCategoryDropdownQuery();
  const { data: typeDataDropdown } = useGetTypeDropdownQuery();

  const [searchData, setSearchData] = useState<string>();
  const [brandData, setBrandData] = useState<dropdownStateType[]>([
    dropdownDefaultValue,
  ]);
  const [categoryData, setCategoryData] = useState<dropdownStateType[]>([
    dropdownDefaultValue,
  ]);
  const [typeData, setTypeData] = useState<dropdownStateType[]>([
    dropdownDefaultValue,
  ]);

  useEffect(() => {
    if (typeof brandDataDropdown != "undefined")
      setBrandData(brandData.concat(brandDataDropdown));
  }, [brandDataDropdown]);

  useEffect(() => {
    if (typeof categoryDataDropdown != "undefined")
      setCategoryData(categoryData.concat(categoryDataDropdown));
  }, [categoryDataDropdown]);

  useEffect(() => {
    if (typeof typeDataDropdown != "undefined")
      setTypeData(typeData.concat(typeDataDropdown));
  }, [typeDataDropdown]);
  return (
    <>
      <div className="h-full w-[20%] flex flex-col items-start text-base font-bold">
        <div>Product Name</div>
        <div className="w-full h-[40px]">
          <SearchComponent
            placeholder="Input product"
            setValue={(value: any) => FormDataHandler("searchProduct", value)}
          />
        </div>
      </div>
      <div className="h-full w-[20%] "></div>
      <div className="h-full w-[60%] flex gap-[2%]">
        <div className="h-full w-[32%]  flex flex-col items-start text-base font-bold ">
          <label>Brand</label>
          <div className="w-full">
            <DropdownComponent2
              defaultSelected={brandData[0].value}
              style={{ width: "100%", textAlign: "initial" }}
              options={brandData ? brandData : []}
              onSelect={(value: any) => FormDataHandler("brand", value)}
            />
          </div>
        </div>
        <div className="h-full w-[32%] flex flex-col items-start text-base font-bold">
          <label>Category</label>
          <div className="w-full">
            <DropdownComponent2
              defaultSelected={categoryData[0].value}
              style={{ width: "100%", textAlign: "initial" }}
              options={categoryData ? categoryData : []}
              onSelect={(value: any) => FormDataHandler("category", value)}
            />
          </div>
        </div>
        <div className="h-full w-[32%]  flex flex-col items-start text-base font-bold">
          <label>Type</label>
          <div className="w-full">
            <DropdownComponent2
              defaultSelected={typeData[0].value}
              style={{ width: "100%", textAlign: "initial" }}
              options={typeData ? typeData : []}
              onSelect={(value: any) => FormDataHandler("type", value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterComponent;
