import {
  AppstoreOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
export const TabComponentConstant = [
  {
    key: "All Products",
    icon: AppstoreOutlined,
  },
  {
    key: "Active",
    icon: CheckCircleOutlined,
  },
  {
    key: "Inactive",
    icon: CloseCircleOutlined,
  },
];

export const dropdownDefaultValue = { value: "All", label: "All" };
