import React, { useState } from "react";
import { ThreeDotsIcon } from "../../../../../assets";
import PackageMenuTooltip from "./PackageMenuTooltip";

interface Props {
  SelectedItemHandler?: (type: { id: string; itemName: string }) => void;
  PackageMenuTooltipHandler?: (type: string) => void;
  HandleSelectedPackage?: (packageName: string) => void;
  selectedPackage?: string | "";
  dataPackage: string | "";
}

function PackageList({
  HandleSelectedPackage,
  SelectedItemHandler,
  selectedPackage,
  dataPackage,
  PackageMenuTooltipHandler,
}: Props) {
  const [packageTooltip, setPackageTooltip] = useState<boolean>(false);

  return (
    <div
      onClick={() =>
        HandleSelectedPackage
          ? HandleSelectedPackage(dataPackage)
          : () => undefined
      }
      className={`flex justify-between items-center h-[56px] text-lg font-bold cursor-pointer relative ${
        selectedPackage == dataPackage ? "bg-slate-200 rounded-lg" : ""
      }`}
    >
      <div className=" ml-8 select-none">{dataPackage}</div>
      <img
        onClick={() => setPackageTooltip(!packageTooltip)}
        src={ThreeDotsIcon}
      />
      {packageTooltip && (
        <div
          onMouseLeave={() => setPackageTooltip(!packageTooltip)}
          className="absolute right-0 top-4 z-40 h-[110px] flex items-end "
        >
          <PackageMenuTooltip
            PackageMenuTooltipHandler={PackageMenuTooltipHandler}
            usedFor="Package"
          />
        </div>
      )}
    </div>
  );
}

export default PackageList;
