export const Endpoints = {
  getProductCategory: "products/categories",

  searchProduct: "products",
  getProductDetail: "products/",
  addProduct: "products",

  getBrand: "products/brands",
  getBrandDropdown: "products/brands/dropdown",
  deleteBrand: "products/brands/",
  createBrand: "products/brands/create",

  getParentCategory: "products/categories/parent",
  getChildCategory: "products/categories/child/",
  getCategoryDropdown: "products/categories/child/dropdown",
  createCategory: "products/categories/create",
  updateCategory: "products/categories/update/",
  deleteParentCategory: "products/categories/parent/",
  deleteChildCategory: "products/categories/child/",

  getPackage: "package",
  getPackageDetail: "package/",

  getType: "/type",
  getTypeDropdown: "products/types/dropdown",
  createType: "products/types/create",
  deleteType: "products/types/",

  getPackageCategory: "package/category",
}
