import { baseApi } from "../../app/Redux/baseApi";
import { Endpoints } from "../../constants/EndPoints";
import { ProductCategory, Dropdown } from "./types";

export const CategoryApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getParentCategory: builder.query<ProductCategory[], void>({
      query: () => ({
        url: Endpoints.getParentCategory,
        method: "GET",
      }),
      providesTags: (result, error, page) =>
        result ? ["ProductParentCategory"] : [],
      transformResponse: (response: { data: ProductCategory[] }) => {
        return response.data;
      },
    }),
    getChildCategory: builder.mutation<ProductCategory[], string>({
      query: (id: string) => ({
        url: Endpoints.getChildCategory + id,
        method: "GET",
      }),
      transformResponse: (response: { data: ProductCategory[] }) => {
        return response.data;
      },
    }),
    getCategoryDropdown: builder.query<Dropdown[], void>({
      query: () => ({
        url: Endpoints.getCategoryDropdown,
        method: "GET",
      }),
      providesTags: (result, error, page) =>
        result ? ["ProductCategoryDropdown"] : [],
      transformResponse: (response: { data: Dropdown[] }) => {
        return response.data;
      },
    }),
    createParentCategory: builder.mutation<any, FormData>({
      query: (body) => ({
        url: Endpoints.createCategory,
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error) => (!error ? ["ProductParentCategory"] : []),
    }),
    createChildCategory: builder.mutation<any, FormData>({
      query: (body) => ({
        url: Endpoints.createCategory,
        method: "POST",
        body,
      }),
    }),
    updateCategory: builder.mutation<any, { id: string; body: FormData }>({
      query: ({ id, body }) => ({
        url: Endpoints.updateCategory + id,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_, error) => (!error ? ["ProductParentCategory"] : []),
    }),
    deleteChildCategory: builder.mutation<any, string>({
      query: (id: string) => ({
        url: Endpoints.deleteChildCategory + id,
        method: "DELETE",
      }),
    }),
    deleteParentCategory: builder.mutation<any, string>({
      query: (id: string) => ({
        url: Endpoints.deleteParentCategory + id,
        method: "DELETE",
      }),
      invalidatesTags: (_, error) => (!error ? ["ProductParentCategory"] : []),
    }),
  }),
});

export const {
  useGetParentCategoryQuery,
  useGetChildCategoryMutation,
  useGetCategoryDropdownQuery,
  useCreateParentCategoryMutation,
  useCreateChildCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteChildCategoryMutation,
  useDeleteParentCategoryMutation,
} = CategoryApi;
