// import {
//   FormControl,
//   FormControlLabel,
//   IconButton,
//   Radio,
//   RadioGroup,
//   Tooltip,
// } from "@mui/material"
import { FormikErrors, FormikTouched } from "formik"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
// import { selectAuth } from "../../../features/authentication/authSlice"
// import { useGetShopExpeditionsQuery } from "../../../features/expedition/api"
import {
  AddProductForm,
  AddProductVariationForm,
  AddProductVariationInitialValue,
  ProductMedia,
} from "../../../../features/Product/types"
import { PrimaryButton } from "../../../../components/Common/Button"
import Image from "../../../../components/Common/Image"
import { Body, Headline, LateGrowTitle } from "../../../../components/Text"
import TextAreaField from "../../../../components/Input/TextArea"
import TextFieldWithIcon from "../../../../components/Input/TextFieldWithIcon"
import TextField from "../../../../components/Input/TextField"
import SelectField from "../../../../components/Input/SelectField"

type ProductAddModifyFormProps = {
  handleChange: (e: React.ChangeEvent<any>) => void
  handleBlur: (e: React.FocusEvent<any, Element>) => void
  errors: FormikErrors<AddProductForm>
  touched: FormikTouched<AddProductForm>
  values: any
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<AddProductForm>>
  isCreate?: boolean
  medias?: ProductMedia[]
  setErrors?: (errors: FormikErrors<AddProductForm>) => void
}

const ProductAddModifyForm = ({
  errors,
  touched,
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  isCreate,
  medias,
  setErrors,
}: ProductAddModifyFormProps) => {
  const [PostCategoryOption, setCategory] = useState<{ [k: string]: string }>(
    {}
  )
  const [checkedExpedition, setCheckedExpedition] = useState<any[]>([])
  const [checkedRemovedMedias, setCheckedRemovedMedias] = useState<any[]>([])
  const [inputVariationFields, setInputVariationFields] = useState<
    AddProductVariationForm[]
  >([])
  const [inputMediaFields, setInputMediaFields] = useState<File[]>([])
  const [radioValue, setRadioValue] = useState("default")
  // const { shopSlug } = useSelector(selectAuth)

  // const { data: categories } = useGetProductCategoryQuery()
  // const { data: expeditions } = useGetShopExpeditionsQuery(shopSlug as string)

  // useEffect(() => {
  //   if (categories) {
  //     var categoryTemp: { [k: string]: string } = {}
  //     categoryTemp["-"] = ""
  //     categories.forEach((e) => {
  //       categoryTemp[e.name] = e.id
  //     })
  //     setCategory({
  //       ...categoryTemp,
  //     })
  //   }
  // }, [categories])

  // useEffect(() => {
  //   setFieldValue("variations", inputVariationFields)
  // }, [inputVariationFields, setFieldValue])

  // useEffect(() => {
  //   let empty: AddProductVariationForm[] = []
  //   let newfield = { ...AddProductVariationInitialValue }
  //   if (radioValue === "default") {
  //     newfield.variation_name = "DEFAULT"
  //     newfield.variation_selection = "DEFAULT"
  //     setInputVariationFields([...empty, newfield])
  //   } else {
  //     newfield.variation_name = ""
  //     newfield.variation_selection = ""
  //     setInputVariationFields([...empty, newfield])
  //   }
  // }, [radioValue, setRadioValue])

  // useEffect(() => {
  //   setFieldValue("product_medias", inputMediaFields)
  // }, [inputMediaFields, setFieldValue])

  // useEffect(() => {
  //   if (values.expeditions) {
  //     setCheckedExpedition(values.expeditions)
  //   }
  // }, [values.expeditions])

  // const expeditionHandleToggle = (c: any) => () => {
  //   const clickedExpedition = checkedExpedition.indexOf(c)
  //   const all = [...checkedExpedition]

  //   if (clickedExpedition === -1) {
  //     all.push(c)
  //   } else {
  //     all.splice(clickedExpedition, 1)
  //   }
  //   setCheckedExpedition(all)
  //   setFieldValue("expeditions", all)
  // }

  // const removedMediasHandleToggle = (c: any) => () => {
  //   const clickedRemovedMedias = checkedRemovedMedias.indexOf(c)
  //   const all = [...checkedRemovedMedias]

  //   if (clickedRemovedMedias === -1) {
  //     all.push(c)
  //   } else {
  //     all.splice(clickedRemovedMedias, 1)
  //   }
  //   setCheckedRemovedMedias(all)
  //   setFieldValue("removed_medias", all)
  // }

  // const addVariationFields = () => {
  //   let newfield = { ...AddProductVariationInitialValue }

  //   newfield.variation_name = inputVariationFields[0].variation_name
  //   newfield.name_2 = inputVariationFields[0].name_2

  //   setInputVariationFields([...inputVariationFields, newfield])
  // }

  // let removeVariationFields = (i: number) => {
  //   let newFormValues = [...inputVariationFields]
  //   newFormValues.splice(i, 1)
  //   setInputVariationFields(newFormValues)
  // }

  // const addMediasFields = () => {
  //   let newfield = {} as File

  //   setInputMediaFields([...inputMediaFields, newfield])
  // }

  // let removeMediasFields = (i: number) => {
  //   let newFormValues = [...inputMediaFields]
  //   newFormValues.splice(i, 1)
  //   setInputMediaFields(newFormValues)
  // }

  // const setVariationName1 = (value: string) => {
  //   let newFormValues = [...inputVariationFields]
  //   for (const newFormValue of newFormValues) {
  //     newFormValue.variation_name = value
  //   }
  //   setInputVariationFields(newFormValues)
  // }

  // const setVariationName2 = (value: string) => {
  //   let newFormValues = [...inputVariationFields]
  //   for (const newFormValue of newFormValues) {
  //     newFormValue.name_2 = value
  //   }
  //   setInputVariationFields(newFormValues)
  // }

  return (
    <>
      <div className="my-1 p-3 border border-stone-200 rounded custom-shadow">
        <div className="my-1">
          <LateGrowTitle className="font-bold mb-5 mx-3">
            Product Information
          </LateGrowTitle>
          <div className="grid lg:grid-cols-4 gap-8 mx-3 mb-7">
            <div className="col-span-1">
              <Headline className="text-stone-700 font-bold">Name</Headline>
              <Body className="text-justify">
                Product name by entering brand, product type, color, material or
                type. <br />
                It is recommended not to use excess capital letters, enter more
                than 1 brand, and promotional words.
              </Body>
            </div>
            <div className="col-span-3">
              <TextField
                onChange={handleChange}
                // error={errors.name}
                value={values.name}
                name="name"
                type="text"
                placeholder="Product name"
              />
            </div>
          </div>

          <div className="grid lg:grid-cols-4 gap-8 mx-3 mb-3">
            <div className="col-span-1">
              <Headline className="text-stone-700 font-bold">Category</Headline>
              <Body className="text-justify">
                Select the appropriate category for the product.
              </Body>
            </div>
            <div className="col-span-3">
              <SelectField
                // onChange={(e) => {
                //   setFieldValue("category_id", e.target.value)
                // }}
                // error={errors.category_id}
                value={values.category_id}
                name="category_id"
                type="text"
                placeholder="Product category"
              >
                {Object.entries(PostCategoryOption).map(([key, value]) => {
                  return (
                    <option key={value} value={value}>
                      {key}
                    </option>
                  )
                })}
              </SelectField>
            </div>
          </div>
        </div>
      </div>

      <div className="my-1 p-3 border border-stone-200 rounded custom-shadow">
        <div className="my-1">
          <LateGrowTitle className="font-bold mb-5 mx-3">
            Product Detail
          </LateGrowTitle>
          <div className="grid lg:grid-cols-4 gap-8 mx-3 mb-7">
            <div className="col-span-1">
              <Headline className="text-stone-700 font-bold">
                Description
              </Headline>
              <Body className="text-justify">
                Make sure the product description contains a detailed
                explanation regarding your product so that buyers can easily
                understand and find your product.
              </Body>
            </div>
            <div className="col-span-3">
              <TextAreaField
                onChange={handleChange}
                // error={errors.description}
                value={values.description}
                name="description"
                placeholder="Product description"
                row={10}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="my-1 p-3 border border-stone-200 rounded custom-shadow">
        <div className="my-1">
          <LateGrowTitle className="font-bold mb-5 mx-3">
            Product Price
          </LateGrowTitle>
          <div className="grid lg:grid-cols-4 gap-8 mx-3 mb-3">
            <div className="col-span-1">
              <Headline className="text-stone-700 font-bold">Price</Headline>
              <Body className="text-justify">
                Price of the product in rupiah.
              </Body>
            </div>
            <div className="col-span-3">
              <TextFieldWithIcon
                onChange={handleChange}
                // error={errors.price}
                // value={values.price.toString()}
                name="price"
                type="number"
                placeholder="Product price in Rupiah"
                leftElement={<div className="text-gray-darker">Rp</div>}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="my-1 p-3 border border-stone-200 rounded custom-shadow">
        <div className="my-1">
          <LateGrowTitle className="font-bold mb-5 mx-3">
            Product Management
          </LateGrowTitle>
          <div className="grid lg:grid-cols-4 gap-8 mx-3 mb-7">
            <div className="col-span-1">
              <Headline className="text-stone-700 font-bold">
                Product Status
              </Headline>
              <Body className="text-justify">
                If the status is active, your product can be searched by buyers.
              </Body>
            </div>
            <div className="col-span-3">
              <SelectField
                // onChange={(e) => {
                //   setFieldValue("status", e.target.value)
                // }}
                // error={errors.status}
                value={values.status}
                name="status"
                type="text"
                placeholder="Product status"
              >
                <option key="active" value="active">
                  Active
                </option>
                <option key="inactive" value="inactive">
                  Inactive
                </option>
              </SelectField>
            </div>
          </div>
        </div>
      </div>

      {isCreate && (
        <div className="my-1 p-3 border border-stone-200 rounded custom-shadow">
          <div className="my-1">
            <LateGrowTitle className="font-bold mb-5 mx-3">
              Product Variant
            </LateGrowTitle>
            <div className="grid lg:grid-cols-4 gap-8 mx-3 mb-7">
              <div className="col-span-1">
                <Headline className="text-stone-700 font-bold">
                  Set Product Variant
                </Headline>
                <Body className="text-justify">
                  Set the product variant if the product have one or multiple
                  variants. <br />
                  The product variants <b>cannot be changed</b> once the product
                  has been Created
                </Body>
              </div>
              <div className="col-span-3">
                {/* <InputRow
                  inputElement={
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={radioValue}
                        onChange={(e) => {
                          setRadioValue(e.target.value)
                        }}
                      >
                        <FormControlLabel
                          value="default"
                          control={<Radio />}
                          label="One variant product"
                        />
                        <FormControlLabel
                          value="variants"
                          control={<Radio />}
                          label="Multiple variants product"
                        />
                      </RadioGroup>
                    </FormControl>
                  }
                /> */}
              </div>
            </div>

            {isCreate &&
              radioValue === "default" &&
              inputVariationFields.map((element, index) => (
                <>
                  <div className="grid lg:grid-cols-4 gap-8 mx-3 mb-7">
                    <div className="col-span-1">
                      <Headline className="text-stone-700 font-bold">
                        Product Stock
                      </Headline>
                      <Body className="text-justify">
                        Add product stock quantity
                      </Body>
                    </div>
                    <div className="col-span-3" key={index}>
                      <TextField
                        // onChange={(e) => {
                        //   let newFormValues = [...inputVariationFields]
                        //   newFormValues[0].stock = parseInt(e.target.value)
                        //   setInputVariationFields(newFormValues)
                        // }}
                        // value={element.stock.toString() || ""}
                        name="stock"
                        type="number"
                        placeholder="Product variation stock"
                      />
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-4 gap-8 mx-3 mb-3">
                    <div className="col-span-1">
                      <Headline className="text-stone-700 font-bold">
                        Product SKU (Stock Keeping Unit)
                      </Headline>
                      <Body className="text-justify">
                        Use a unique SKU code if you want to mark your product.
                      </Body>
                    </div>
                    <div className="col-span-3" key={index}>
                      <TextField
                        // onChange={(e) => {
                        //   let newFormValues = [...inputVariationFields]
                        //   newFormValues[0].sku = e.target.value
                        //   setInputVariationFields(newFormValues)
                        // }}
                        // value={element.sku || ""}
                        name="sku"
                        type="text"
                        placeholder="Product variation SKU"
                      />
                    </div>
                  </div>
                </>
              ))}

            {isCreate &&
              radioValue !== "default" &&
              inputVariationFields.map((element, index) => (
                <>
                  {index === 0 && (
                    <div className="grid lg:grid-cols-4 gap-8 mx-3 mb-3">
                      <div className="col-span-1"></div>
                      <div className="col-span-3">
                        <div className="md:flex md:flex-row gap-3 font-bold">
                          <div className="w-full">
                            <TextField
                              // onChange={(e) => {
                              //   setVariationName1(e.target.value)
                              // }}
                              value={element.variation_name || ""}
                              name="variation_name"
                              type="text"
                              placeholder="Variation name 1"
                            />
                          </div>
                          <div className="w-full">
                            <TextField
                              // onChange={(e) => {
                              //   setVariationName2(e.target.value)
                              // }}
                              // value={element.name_2 || ""}
                              name="name_2"
                              type="text"
                              placeholder="Variation name 2"
                            />
                          </div>
                          <div className="w-full">
                            <Headline>Additional Price</Headline>
                          </div>
                          <div className="w-full">
                            <Headline>Stock</Headline>
                          </div>
                          <div className="w-full">
                            <Headline>SKU</Headline>
                          </div>
                          <div className="w-1/6"></div>
                        </div>
                        <hr className="w-full border-stone-400" />
                      </div>
                    </div>
                  )}
                  <div className="grid lg:grid-cols-4 gap-8 mx-3 mb-3">
                    <div className="col-span-1">
                      <Headline className="text-stone-700 font-bold">
                        Product Variation {index + 1} Value
                      </Headline>
                    </div>
                    <div className="col-span-3" key={index}>
                      <div className="md:flex md:flex-row gap-3">
                        <div className="w-full">
                          <TextField
                            onChange={(e) => {
                              let newFormValues = [...inputVariationFields]
                              newFormValues[index].variation_selection =
                                e.target.value
                              setInputVariationFields(newFormValues)
                            }}
                            value={element.variation_selection || ""}
                            name="variation_selection"
                            type="text"
                            placeholder="Variation value 1"
                          />
                        </div>
                        {/* <div className="w-full">
                          <TextField
                            onChange={(e) => {
                              let newFormValues = [...inputVariationFields]
                              newFormValues[index].selection_2 = e.target.value
                              setInputVariationFields(newFormValues)
                            }}
                            value={element.selection_2 || ""}
                            name="selection_2"
                            type="text"
                            placeholder="Variation value 2"
                          />
                        </div> */}
                        <div className="w-full">
                          <TextFieldWithIcon
                            onChange={(e) => {
                              let newFormValues = [...inputVariationFields]
                              newFormValues[index].variation_price = parseInt(
                                e.target.value
                              )
                              setInputVariationFields(newFormValues)
                            }}
                            value={element.variation_price.toString() || ""}
                            name="variation_price"
                            type="number"
                            placeholder="Additional price in Rupiah"
                            leftElement={
                              <div className="text-gray-darker">Rp</div>
                            }
                          />
                        </div>
                        {/* <div className="w-full">
                          <TextField
                            onChange={(e) => {
                              let newFormValues = [...inputVariationFields]
                              newFormValues[index].stock = parseInt(
                                e.target.value
                              )
                              setInputVariationFields(newFormValues)
                            }}
                            value={element.stock.toString() || ""}
                            name="stock"
                            type="number"
                            placeholder="Stock"
                          />
                        </div> */}
                        <div className="w-full">
                          <TextField
                            onChange={(e) => {
                              let newFormValues = [...inputVariationFields]
                              newFormValues[index].variation_sku =
                                e.target.value
                              setInputVariationFields(newFormValues)
                            }}
                            value={element.variation_sku || ""}
                            name="sku"
                            type="text"
                            placeholder="SKU"
                          />
                        </div>
                        <div className="w-1/4">
                          {index ? (
                            <div title="Remove variation">
                              <div
                              // onClick={() => {
                              //   removeVariationFields(index)
                              // }}
                              >
                                {/* <RemoveCircleIcon className="text-red" /> */}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            {isCreate && radioValue !== "default" && (
              <div className="my-3">
                <PrimaryButton
                  type="button"
                  // onClick={() => addVariationFields()}
                >
                  Add variation
                </PrimaryButton>
              </div>
            )}
          </div>
        </div>
      )}

      {!isCreate && medias && (
        <div className="my-1 p-3 border border-stone-200 rounded custom-shadow">
          <div className="my-1">
            <LateGrowTitle className="font-bold mb-5 mx-3">
              Product Medias
            </LateGrowTitle>
            <div className="grid lg:grid-cols-4 gap-8 mx-3 mb-7">
              <div className="col-span-1">
                <Headline className="text-stone-700 font-bold">
                  Product Medias
                </Headline>
                <Body className="text-justify">
                  Check to remove the existing product medias.
                </Body>
              </div>
              <div className="col-span-3">
                <li className="list-none flex flex-row">
                  {medias.map((element, index: number) => (
                    <div key={element.id} className="mr-3">
                      {element.media_type === "image" ? (
                        <a href={element.url} target="_blank" rel="noreferrer">
                          <Image
                            className="w-[75px] h-[75px] border-[0.1px] border-gray-lighter rounded-md shadow-lg"
                            src={element.url}
                          />
                        </a>
                      ) : (
                        <a href={element.url} target="_blank" rel="noreferrer">
                          <div className="sw-[75px] h-[75px] relative border-[0.1px] border-gray-lighter rounded-md shadow-lg">
                            <Image
                              className={`bg-gray w-full h-full aspect-square`}
                            />
                            {/* <PlayCircleFilled
                              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white"
                              fontSize="large"
                            /> */}
                          </div>
                        </a>
                      )}
                      <input
                        // onChange={removedMediasHandleToggle(element.id)}
                        type="checkbox"
                        className="mx-auto min-w-full"
                      />
                    </div>
                  ))}
                </li>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="my-1 p-3 border border-stone-200 rounded custom-shadow">
        <div className="my-1">
          <LateGrowTitle className="font-bold mb-5 mx-3">
            Upload Product
          </LateGrowTitle>
          <div className="grid lg:grid-cols-4 gap-8 mx-3 mb-7">
            <div className="col-span-1">
              <Headline className="text-stone-700 font-bold">
                Product Medias
              </Headline>
              <Body className="text-justify">
                Add attractive medias of the product to make the product more
                attractive to buyers.
              </Body>
            </div>
            <div className="col-span-3">
              {inputMediaFields.map((_element, index) => (
                <div className="items-center w-full" key={index}>
                  <div className="grid grid-cols-5 border border-gray-lighter rounded p-2 my-2">
                    <input
                      className="col-span-4"
                      id="product_medias"
                      name="product_medias"
                      type="file"
                      onChange={(e) => {
                        let newFormValues = [...inputMediaFields]
                        newFormValues[index] = e.currentTarget?.files?.length
                          ? e.currentTarget.files[0]
                          : ({} as File)
                        setInputMediaFields(newFormValues)
                      }}
                    />
                    {index ? (
                      <div title="Remove media">
                        <div
                        // onClick={() => {
                        //   removeMediasFields(index)
                        // }}
                        >
                          {/* <RemoveCircleIcon className="text-red" /> */}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
              <div className="button-section">
                <PrimaryButton
                  type="button"
                  // onClick={() => addMediasFields()}
                >
                  Add medias
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(errors.category_id ||
        errors.description ||
        errors.name ||
        errors.price ||
        errors.product_medias ||
        errors.status ||
        errors.variations) && (
        <div className="text-red whitespace-pre-line my-3">
          <Headline>
            This fields is required: <br />
            {errors.category_id && "* " + errors.category_id + "\n"}
            {errors.description && "* " + errors.description + "\n"}
            {errors.name && "* " + errors.name + "\n"}
            {errors.price && "* " + errors.price + "\n"}
            {errors.product_medias && "* " + errors.product_medias + "\n"}
            {errors.status && "* " + errors.status + "\n"}
            {errors.variations &&
              "Variations field required:" +
                JSON.stringify(errors.variations)
                  .replace("[", "")
                  .replace("]", "")
                  .replace(/{/g, "\n-")
                  .replace(/}/g, "")
                  .replace(/"/g, " ") +
                "\n"}
          </Headline>
        </div>
      )}
    </>
  )
}

export default ProductAddModifyForm
