import { useState, useEffect } from "react"
import {
  PaginationComponent,
  AutocompleteComponent,
  DropdownComponent,
  ButtonComponent,
  Modal,
  DeleteConfirmation,
  Skeleton,
} from "../../../components"
import { BrandIcon } from "../../../assets"
import "./Brand.css"
import {
  PlusCircleOutlined,
  RedoOutlined,
  RestOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons"
import BrandCard from "./component/BrandCard"
import PopUpBrand from "./component/PopUpBrand"
import { DataAutoComplete, PaginationProcess } from "./hooks/BrandFunction"
import { useGetBrandQuery } from "../../../features/Brand/api"
import { brandDataObject } from "./type/BrandType"

const Brand = () => {
  const { data: brandDataQuery, isLoading } = useGetBrandQuery()
  const [brandData, setBrandData] = useState<brandDataObject[]>([
    { brand_id: "", brand_name: "", image_url: "", brand_code: "" },
  ])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [selectedBrand, setSelectedBrand] = useState<brandDataObject>(
    brandData[0]
  )
  const [autocompleteVal, setAutocompleteVal] = useState<string>("")
  const [brandModal, setBrandModal] = useState<boolean>(false)
  const [buttonVal, setButtonVal] = useState<string>("")
  const [sortingVal, setSortingVal] = useState<string>("Ascending")

  useEffect(() => {
    if (typeof brandDataQuery != "undefined") {
      if (brandDataQuery != null) {
        setBrandData(brandDataQuery)
        setSelectedBrand(brandDataQuery[0])
      }
    }
  }, [brandDataQuery])

  const { dataFiltered, page } = PaginationProcess(
    autocompleteVal,
    sortingVal,
    brandData,
    currentPage
  )

  const skeletonArray: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  console.log(page)
  return (
    <div className="w-full h-full p-4 flex flex-col justify-start ">
      {brandModal && (
        <Modal
          modal={brandModal}
          setModal={setBrandModal}
          screenPopUp={
            buttonVal == "Delete" ? (
              <DeleteConfirmation
                selectedItem={{
                  id: selectedBrand.brand_id,
                  itemName: selectedBrand.brand_name,
                }}
                modal={brandModal}
                cancelButtonOnClick={setBrandModal}
                screen="Brand"
              />
            ) : (
              <PopUpBrand
                selectedItem={selectedBrand}
                brandModal={brandModal}
                cancelButtonOnClick={setBrandModal}
                popupName={buttonVal}
              />
            )
          }
        />
      )}

      <div className=" w-full h-[7%] bg-[#D3C4BE] flex items-center justify-between">
        <div className="flex items-center">
          <img className="w-[58px] ml-2" src={BrandIcon} />
          <div className="text-4xl font-bold ml-2 font-serif">Brand</div>
        </div>
        <div className=" bg-gray-500 text-zinc-300 p-2 mr-2 flex items-center justify-center rounded-md">
          <label className=" text-lg">Total Brand</label>
          <div className=" bg-white p-1 px-2 rounded-md ml-2 text-black text-sm">
            {brandData.length}
          </div>
        </div>
      </div>
      <div className=" w-full h-[88%] p-2">
        <div className="w-full h-[5%] flex items-center">
          <div className="h-full flex items-center">
            <AutocompleteComponent
              placeholder={"Input Brand Name"}
              options={DataAutoComplete(brandData)}
              onChange={setAutocompleteVal}
              style={{
                width: "200px",
                display: "flex",
                justifyContent: "start",
              }}
            />
          </div>
          <div className="ml-[25px]">
            <DropdownComponent
              setSortingVal={setSortingVal}
              selectedDropdownVal={"Sort by Name"}
              itemsDropdown={[
                {
                  key: "Ascending",
                  label: "A-Z",
                  icon: <SortAscendingOutlined />,
                },
                {
                  key: "Descending",
                  label: "Z-A",
                  icon: <SortDescendingOutlined />,
                },
              ]}
            />
          </div>
        </div>
        <div className=" w-full h-[88%] bg-[#C9D6DF] flex flex-wrap p-9  gap-[3%]">
          {isLoading &&
            skeletonArray.map((value: number) => <Skeleton key={value} />)}
          {typeof page != "undefined" &&
            !isLoading &&
            page.map((value: brandDataObject, index: number) => (
              <BrandCard
                key={index}
                setSelectedBrand={setSelectedBrand}
                brandVal={value}
                selectedValFlag={
                  selectedBrand.brand_id == value.brand_id ? true : false
                }
              />
            ))}
        </div>
        <div className="w-full h-[7%] flex items-center">
          <ButtonComponent
            icon={<PlusCircleOutlined />}
            buttonLabel={"Create"}
            style={
              "h-[55px] w-[160px] text-lg bg-[#73d9e2] text-white font-bold"
            }
            buttonOnClick={setBrandModal}
            setButtonVal={setButtonVal}
            buttonBoolVal={brandModal}
          />
          <ButtonComponent
            icon={<RedoOutlined />}
            buttonLabel={"Update"}
            style={
              "h-[55px] w-[160px] text-lg ml-[8px] bg-[#a6df98] text-white font-bold"
            }
            buttonOnClick={setBrandModal}
            setButtonVal={setButtonVal}
            buttonBoolVal={brandModal}
          />
          <ButtonComponent
            icon={<RestOutlined />}
            buttonLabel={"Delete"}
            style={
              "h-[55px] w-[160px] text-lg ml-[8px] bg-[#fc9482] text-white font-bold"
            }
            buttonOnClick={setBrandModal}
            setButtonVal={setButtonVal}
            buttonBoolVal={brandModal}
          />
        </div>
      </div>
      <div className=" w-full h-[5%] flex items-center justify-center">
        <PaginationComponent
          count={Math.ceil(dataFiltered.length / 10)}
          setPage={setCurrentPage}
        />
      </div>
    </div>
  )
}

export default Brand
