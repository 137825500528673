import { UpdateIcon, DeleteIcon } from "../../../../../assets"

interface Props {
  PackageMenuTooltipHandler?: (type: string) => void
  usedFor: string
}

const PackageMenuTooltip = ({ PackageMenuTooltipHandler, usedFor }: Props) => {
  return (
    <div className=" bg-white p-2 rounded-xl text-sm ">
      <div
        onClick={() =>
          PackageMenuTooltipHandler
            ? PackageMenuTooltipHandler("Update " + usedFor)
            : () => undefined
        }
        className="p-1 rounded-md hover:bg-slate-300"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          cursor: "pointer",
        }}
      >
        <img style={{ width: "15px", cursor: "pointer" }} src={UpdateIcon} />
        <label style={{ marginBottom: "4px", cursor: "pointer" }}>Update</label>
      </div>
      <div
        onClick={() =>
          PackageMenuTooltipHandler
            ? PackageMenuTooltipHandler("Delete")
            : () => undefined
        }
        className="p-1 rounded-md hover:bg-slate-300"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          cursor: "pointer",
        }}
      >
        <img style={{ width: "15px", cursor: "pointer" }} src={DeleteIcon} />
        <label style={{ marginBottom: "4px", cursor: "pointer" }}>Delete</label>
      </div>
    </div>
  )
}

export default PackageMenuTooltip
