import { useState, useEffect } from "react"
import {
  ButtonComponent,
  AutocompleteComponent,
  Modal,
} from "../../../../components"
import { Divider, Input, Upload, Form } from "antd"
import { CreateIcon, UpdateIcon } from "../../../../assets"
import PopUpAddCategory from "./PopUpAddCategory"

const FormItem = Form.Item

interface Props {
  selectedItem: any
  cancelButtonOnClick: Function
  packageModal: boolean
  popupName: string
}

const GetPopUpTypeForFormName = (name: string) => {
  const stringArray = name.split(" ")
  console.log(stringArray)
  return name == "Update Package Category"
    ? stringArray[1] + " " + stringArray[2]
    : stringArray[1]
}

function PackagePopUp(props: Props) {
  const { selectedItem, cancelButtonOnClick, packageModal, popupName } = props
  const updtVisFlag: boolean = popupName == "Add Package" ? true : false
  const [autoCompleteVal, setAutocompleteVal] = useState<any>()
  const [productPopUpModal, setProductPopUpModal] = useState<boolean>(false)
  const [optionsAutoComplete, setOptionsAutoComplete] = useState<any>()
  const OnFinishSubmitHandler = (values: any) => {
    console.log(values)
  }
  const renderTitle = (title: string) => (
    <div className="flex items-center justify-between ">{title}</div>
  )
  const options = [
    {
      label: renderTitle("test"),
      value: "test",
    },
    {
      label: renderTitle("test1"),
      value: "test1",
    },
    {
      label: renderTitle("test2"),
      value: "test2",
    },
  ]

  useEffect(() => {
    setOptionsAutoComplete(options)
  }, [])

  if (autoCompleteVal == "Add Type") {
    setAutocompleteVal("AddType")
    setProductPopUpModal(!productPopUpModal)
  }
  return (
    <div className="w-[1000px]">
      {productPopUpModal && (
        <Modal
          setModal={setProductPopUpModal}
          modal={productPopUpModal}
          classNameOverlay="overlay-modal-popup"
          classNameContent="modal-content-popup"
          classNameModal="modal-popup"
          screenPopUp={
            <PopUpAddCategory
              cancelButtonOnClick={setProductPopUpModal}
              typeModal={productPopUpModal}
            />
          }
        />
      )}
      <div className="w-full flex justify-start items-center text-4xl font-bold font-serif">
        <img className="w-[40px]" src={updtVisFlag ? CreateIcon : UpdateIcon} />
        <div className="ml-2">{popupName}</div>
      </div>
      <Divider />
      <Form
        onFinish={OnFinishSubmitHandler}
        name="BrandPopup"
        className="w-full flex items-start flex-col text-lg font-semibold"
      >
        {updtVisFlag && (
          <div className=" px-2 py-7 flex w-full items-center">
            <div className="w-[30%] h-full flex justify-start">
              {GetPopUpTypeForFormName(popupName)} ID
            </div>
            <div className="w-[20%]"></div>
            <div className=" w-[50%]">
              <FormItem noStyle>
                <Input
                  disabled
                  defaultValue={selectedItem.brand_id}
                  size="large"
                  placeholder="Input Brand Name"
                />
              </FormItem>
            </div>
          </div>
        )}
        <div
          className={`p-2 flex w-full items-start ${updtVisFlag ? "" : "mt-4"}`}
        >
          <div className=" w-[30%] flex justify-start mt-1">
            <label style={{ fontSize: "1.125rem", lineHeight: "1.75rem" }}>
              {GetPopUpTypeForFormName(popupName)} Name
            </label>
          </div>
          <div className="w-[20%]"></div>
          <div className=" w-[50%]">
            <FormItem
              name="BrandName"
              initialValue={updtVisFlag ? selectedItem.brand_name : ""}
              rules={[{ required: true, message: "Please input Package Name" }]}
            >
              <Input
                size="large"
                defaultValue={updtVisFlag ? selectedItem.brand_name : ""}
                placeholder={`Input ${GetPopUpTypeForFormName(popupName)} Name`}
              />
            </FormItem>
          </div>
        </div>
        {updtVisFlag && (
          <div className="p-2 flex w-full items-start">
            <div className="w-[30%] flex justify-start mt-1">
              <label style={{ fontSize: "1.125rem", lineHeight: "1.75rem" }}>
                Package Category
              </label>
            </div>
            <div className="w-[20%]"></div>
            <div className="w-[50%] flex itme">
              <FormItem
                name="TypeName"
                rules={[{ required: true, message: "Please input Type Name" }]}
              >
                <AutocompleteComponent
                  placeholder={"Input Package Category"}
                  options={options}
                  onSelect={setAutocompleteVal}
                  notFoundContentOnSelectVal={setAutocompleteVal}
                  notFoundContent="Add Type"
                  notFoundContentModal={productPopUpModal}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                  }}
                />
              </FormItem>
            </div>
          </div>
        )}
      </Form>
      <div className="flex mt-8 w-[100%] justify-center items-center gap-6">
        <FormItem noStyle>
          <ButtonComponent
            buttonLabel="Confirm"
            buttonOnClick={() => undefined}
            buttonBoolVal={packageModal}
            form="BrandPopup"
            htmlType={"submit"}
            style={
              "h-[55px] w-[130px] text-lg bg-[#5ec5ff] text-white font-bold"
            }
          />
        </FormItem>
        <FormItem noStyle>
          <ButtonComponent
            buttonLabel="Cancel"
            htmlType={"button"}
            buttonOnClick={cancelButtonOnClick}
            buttonBoolVal={packageModal}
            style={"h-[55px] w-[130px] text-lg text-black font-bold"}
          />
        </FormItem>
      </div>
    </div>
  )
}

export default PackagePopUp
