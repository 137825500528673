import React from "react"
import { Input } from "antd"
import { ButtonComponent } from "../../../../../components"

function Row() {
  return (
    <div className=" w-full flex items-center ">
      <div className="w-[88%] bg-slate-300 h-full flex items-center rounded-xl font-medium px-4 py-2">
        <div className="w-[30%]">
          <label>Product 1</label>
        </div>
        <div className="w-[25%] ">
          <label>Variant 1</label>
        </div>
        <div className="w-[35%] ">
          <label>200000</label>
        </div>
        <div className="w-[10%]">
          <Input style={{ width: 50, height: 30 }} />
        </div>
      </div>
      <div className="w-[12%]">
        <ButtonComponent
          buttonLabel="Delete"
          buttonOnClick={() => undefined}
          htmlType={"button"}
          style={"h-[35px] w-[70px] text-xs bg-[#fc9482] text-white font-bold "}
        />
      </div>
    </div>
  )
}

export default Row
