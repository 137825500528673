import React from "react";
import { DefaultImage, DeleteCircleIcon } from "../../../../assets";

interface Props {
  item: { name: string };
  DeleteProductHandler: (type: string) => void;
  SelectedItemHandler: (selectedItem: { id: string; itemName: string }) => void;
}

function ProductCard({
  item,
  DeleteProductHandler,
  SelectedItemHandler,
}: Props) {
  const DeleteButtonOnClickHandler = () => {
    SelectedItemHandler({ id: "", itemName: item.name });
    DeleteProductHandler("Delete");
  };
  return (
    <div className=" w-[280px] bg-transparent flex justify-start">
      <div className=" relative flex w-[250px] h-[320px] max-w-xs flex-col overflow-hidden rounded-lg border border-gray-100 bg-white shadow-md ">
        <div className="relative mx-3 mt-3 flex  overflow-hidden rounded-xl">
          <img
            className="w-full h-[200px] object-cover"
            src={DefaultImage}
            alt="product image"
          />
        </div>
        <div className="px-3 mt-3 text-left flex">
          <div className="w-[70%]">
            <p className="m-0 font-semibold">{item.name}</p>
            <p className="my-1 font-light">Brand/Series/type</p>
            <p className="m-0">Desc</p>
            <p className="font-bold">Rp 500,000</p>
          </div>
        </div>
      </div>
      <div className=" ml-1 mt-1 bg-white w-5 h-5 rounded-full flex justify-center items-center">
        <img
          onClick={DeleteButtonOnClickHandler}
          className=" cursor-pointer"
          src={DeleteCircleIcon}
        />
      </div>
    </div>
  );
}

export default ProductCard;
