const Skeleton = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="flex gap-5">
        <div className="relative w-[280px] h-[340px] space-y-3 overflow-hidden rounded-md bg-white p-3 shadow before:absolute before:inset-0 before:-translate-x-full before:bg-gradient-to-r before:from-transparent before:via-white/20 hover:shadow-lg before:animate-[shimmer_1.5s_infinite]">
          <div className="h-[200px] w-full rounded-lg bg-neutral-300"></div>
          <div className="space-y-3">
            <div className="h-5 w-4/12 rounded-full bg-neutral-300"></div>
            <div className="space-y-2">
              <div className="h-4 w-7/12 rounded-full bg-neutral-300 shadow"></div>
              <div className="h-4 w-7/12 rounded-full bg-neutral-300 shadow"></div>
            </div>
            <div className="flex justify-between mb-[15px]">
              <div className="h-5 w-16 rounded-full bg-neutral-300"></div>
              <div className="h-5 w-12 rounded-full bg-neutral-300"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
