import tw from "tailwind-styled-components"

export const BaseInput = tw.input`
    w-full
    p-2
    rounded
    outline
    outline-1
    outline-gray-darker/30
    focus:outline-blue
    transition-colors
    duration-300
`

export const BaseInputWithoutRadius = tw.input`
    w-full
    p-2
    outline
    outline-1
    outline-gray-darker/30
    focus:outline-blue
    transition-colors
    duration-300
`

export const BaseCheckbox = tw.input`
    h-5
    w-5
    rounded
    accent-blue
`

export const BaseSelect = tw.select<any>`
    w-full
    p-2
    rounded
    outline
    outline-1
    outline-gray-darker/30
    focus:outline-blue
    transition-colors
    duration-300
    bg-transparent
`

export const BaseTextArea = tw.textarea`
    w-full
    p-2
    rounded
    outline
    outline-1
    outline-gray-darker/30
    focus:outline-blue
    transition-colors
    duration-300
`
