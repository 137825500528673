import { useState } from "react"
import { Layout } from "antd"
import { Sidebar } from "../components"
import { Outlet } from "react-router-dom"
const { Content, Sider } = Layout

const GenericLayout = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  return (
    <Layout className=" min-h-screen">
      <Sider
        className=" overflow-y-auto"
        collapsible
        onCollapse={(value) => setCollapsed(value)}
      >
        <Sidebar collapseVal={collapsed} />
      </Sider>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  )
}

export default GenericLayout
