import { ButtonComponent } from "../../../../components"
import { Divider, Input, Form } from "antd"
import { CreateIcon, UpdateIcon } from "../../../../assets"
import {
  useCreateChildCategoryMutation,
  useUpdateCategoryMutation,
} from "../../../../features/Category/api"
const FormItem = Form.Item

interface Props {
  parentData: { id: string; parentName: string }
  selectedItem: { id: string; childCategoryName: string }
  cancelButtonOnClick: Function
  categoryModal: boolean
  popupName: string
}

function PopUpAddUpdateChildCategory({
  selectedItem,
  parentData,
  cancelButtonOnClick,
  categoryModal,
  popupName,
}: Props) {
  const updtVisFlag: boolean = popupName == "Update Child" ? true : false

  const [createChildCategoryMutation] = useCreateChildCategoryMutation()
  const [updateChildCategoryMutation] = useUpdateCategoryMutation()

  const OnFinishSubmitHandler = async (values: any) => {
    var bodyFormData = new FormData()
    bodyFormData.append("name", values.ChildCategoryName)
    bodyFormData.append("parent_id", parentData.id)
    if (popupName == "Add Child") {
      await createChildCategoryMutation(bodyFormData)
        .unwrap()
        .catch((err: any) => console.log(err))
        .finally(() => {
          cancelButtonOnClick()
        })
    }
    if (popupName == "Update Child") {
      await updateChildCategoryMutation({
        id: selectedItem.id,
        body: bodyFormData,
      })
        .unwrap()
        .catch((err: any) => console.log(err))
        .finally(() => {
          cancelButtonOnClick()
        })
    }
  }

  return (
    <div className="w-[1000px]">
      <div className="w-full flex justify-start items-center text-4xl font-bold font-serif">
        <img className="w-[40px]" src={updtVisFlag ? UpdateIcon : CreateIcon} />
        <div className="ml-2">{popupName} Category</div>
      </div>
      <Divider />
      <Form
        onFinish={OnFinishSubmitHandler}
        name="ChildCategoryPopup"
        className="w-full flex items-start flex-col text-lg font-semibold"
      >
        <div className=" px-2 py-3 flex w-full items-center">
          <div className="w-[30%] h-full flex justify-start">
            Parent Category ID
          </div>
          <div className="w-[20%]"></div>
          <div className=" w-[50%]">
            <FormItem noStyle>
              <Input disabled defaultValue={parentData.id} size="large" />
            </FormItem>
          </div>
        </div>
        <div className=" px-2 py-3 flex w-full items-center">
          <div className="w-[30%] h-full flex justify-start">
            Parent Category Name
          </div>
          <div className="w-[20%]"></div>
          <div className=" w-[50%]">
            <FormItem noStyle>
              <Input
                disabled
                defaultValue={parentData.parentName}
                size="large"
              />
            </FormItem>
          </div>
        </div>
        {updtVisFlag && (
          <div className=" px-2 py-3 flex w-full items-center">
            <div className="w-[30%] h-full flex justify-start">
              Child Category ID
            </div>
            <div className="w-[20%]"></div>
            <div className=" w-[50%]">
              <FormItem noStyle>
                <Input disabled defaultValue={selectedItem.id} size="large" />
              </FormItem>
            </div>
          </div>
        )}
        <div
          className={`p-2 flex w-full items-center ${
            updtVisFlag ? "" : "mt-4"
          }`}
        >
          <div className=" w-[30%] h-full flex justify-start pb-6">
            Child Category Name
          </div>
          <div className="w-[20%]"></div>
          <div className=" w-[50%]">
            <FormItem
              name="ChildCategoryName"
              initialValue={updtVisFlag ? selectedItem.childCategoryName : ""}
              rules={[
                { required: true, message: "Please input Child Category Name" },
              ]}
            >
              <Input
                size="large"
                defaultValue={updtVisFlag ? selectedItem.childCategoryName : ""}
                placeholder="Input Child Category Name"
              />
            </FormItem>
          </div>
        </div>
      </Form>
      <div className="flex mt-8 w-[100%] justify-center items-center gap-6">
        <FormItem noStyle>
          <ButtonComponent
            buttonLabel="Confirm"
            buttonOnClick={() => undefined}
            buttonBoolVal={categoryModal}
            form="ChildCategoryPopup"
            htmlType={"submit"}
            style={
              "h-[55px] w-[130px] text-lg bg-[#5ec5ff] text-white font-bold"
            }
          />
        </FormItem>
        <FormItem noStyle>
          <ButtonComponent
            buttonLabel="Cancel"
            htmlType={"button"}
            buttonOnClick={cancelButtonOnClick}
            buttonBoolVal={categoryModal}
            style={"h-[55px] w-[130px] text-lg text-black font-bold"}
          />
        </FormItem>
      </div>
    </div>
  )
}

export default PopUpAddUpdateChildCategory
