export const categoryAPIDefaultData = {
  id: "",
  name: "",
  image_url: "",
  parent_id: "",
};

export const selectedParentDefaultData = {
  id: "",
  categoryName: "",
  imageUrl: "",
};

export const selectedChildDefaultData = {
  id: "",
  categoryName: "",
};

export const skeletonParentCategory = [1, 2, 3, 4];

export const skeletonChildCategory = [1, 2, 3, 4, 5, 6, 7];
