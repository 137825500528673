import SelectMenu from "./SelectMenu";

interface Props {
  data: { id: string; childCategoryName: string };
  SelectedMenuHandler: (type: string) => void;
  SelectedChildCategoryHandler: (data: {
    id: string;
    categoryName: string;
    imageUrl: string;
  }) => void;
}

function ChildCategoryCard({
  data,
  SelectedMenuHandler,
  SelectedChildCategoryHandler,
}: Props) {
  return (
    <div
      onClick={() =>
        SelectedChildCategoryHandler({
          id: data.id,
          categoryName: data.childCategoryName,
          imageUrl: "",
        })
      }
      className="w-full h-[60px] bg-slate-200 rounded-md p-3 text-left flex justify-between items-center cursor-pointer hover:bg-slate-600"
    >
      <h3 className="">{data.childCategoryName}</h3>
      <SelectMenu SelectedMenuHandler={SelectedMenuHandler} type="Child" />
    </div>
  );
}

export default ChildCategoryCard;
