import { useState, useEffect } from "react"
import {
  ButtonComponent,
  AutocompleteComponent,
  Modal,
  DeleteConfirmation,
  DropdownComponent2,
} from "../../../../components"
import { Divider, Input, Upload, Form } from "antd"
import PopUpAddType from "./PopUpAddType"
import { InboxOutlined } from "@ant-design/icons"
import { CreateIcon } from "../../../../assets"
// import "./ProductList.css";
const FormItem = Form.Item
const { Dragger } = Upload
const { TextArea } = Input

interface Props {
  cancelButtonOnClick: Function
  productModal: boolean
}

function PopUpProduct(props: Props) {
  const { cancelButtonOnClick, productModal } = props
  const [productPopUpModal, setProductPopUpModal] = useState<boolean>(false)
  const [selectedType, setSelectedType] = useState<{
    id: string
    itemName: string
  }>({
    id: "",
    itemName: "",
  })
  const [autoCompleteVal, setAutocompleteVal] = useState<any>()
  const [optionsAutoComplete, setOptionsAutoComplete] = useState<any>()
  const renderTitle = (title: string) => (
    <div className="flex items-center justify-between ">
      {title}
      <ButtonComponent
        buttonLabel="Delete"
        buttonOnClick={setProductPopUpModal}
        buttonBoolVal={productPopUpModal}
        style={"bg-[#fc9482] text-white font-bold"}
      />
    </div>
  )
  const options = [
    {
      label: renderTitle("test"),
      value: "test",
    },
    {
      label: renderTitle("test1"),
      value: "test1",
    },
    {
      label: renderTitle("test2"),
      value: "test2",
    },
  ]

  useEffect(() => {
    setOptionsAutoComplete(options)
  }, [])

  if (autoCompleteVal === "Add Type") {
    setAutocompleteVal("AddType")
    setProductPopUpModal(!productPopUpModal)
  }
  const OnFinishSubmitHandler = (values: any) => {
    console.log(values)
  }
  return (
    <div className="w-[1000px] h-[900px]">
      {productPopUpModal && (
        <Modal
          setModal={setProductPopUpModal}
          modal={productPopUpModal}
          classNameOverlay="overlay-modal-popup"
          classNameContent="modal-content-popup"
          classNameModal="modal-popup"
          screenPopUp={
            autoCompleteVal === "AddType" || autoCompleteVal === "Add Type" ? (
              <PopUpAddType
                cancelButtonOnClick={setProductPopUpModal}
                typeModal={productPopUpModal}
              />
            ) : (
              <DeleteConfirmation
                selectedItem={selectedType}
                modal={productPopUpModal}
                cancelButtonOnClick={setProductPopUpModal}
              />
            )
          }
        />
      )}
      <div className="w-full h-[5%] flex justify-start items-center text-4xl font-bold font-serif">
        <img className="w-[40px]" src={CreateIcon} />
        <div className="ml-2">Add Product</div>
      </div>
      <Divider />
      <Form
        name="ProductPopup"
        onFinish={OnFinishSubmitHandler}
        className="w-full h-[85%] py-2 px-1 overflow-y-auto flex flex-wrap gap-3"
      >
        <div className="w-full shadow-md shadow-neutral-600 rounded-xl flex items-start flex-col text-lg font-semibold">
          <div className="p-4 flex w-full items-center">
            <div className="w-[20%] h-full flex justify-start">
              Product Name
            </div>
            <div className="w-[30%]"></div>
            <div className=" w-[50%]">
              <FormItem
                name="ProductName"
                rules={[
                  { required: true, message: "Please input Product Name" },
                ]}
              >
                <Input size="large" placeholder="Input Product Name" />
              </FormItem>
            </div>
          </div>
          <div className="p-4 flex w-full items-center">
            <div className="w-[20%] h-full flex justify-start">Brand</div>
            <div className="w-[30%]"></div>
            <div className=" w-[50%]">
              <FormItem
                name="BrandName"
                rules={[
                  { required: true, message: "Please select Brand Name" },
                ]}
              >
                <DropdownComponent2
                  defaultSelected=""
                  style={{ width: "100%", textAlign: "initial" }}
                  options={[
                    { value: "Brand1", label: "Brand1" },
                    { value: "Brand2", label: "Brand2" },
                  ]}
                />
              </FormItem>
            </div>
          </div>
          <div className="p-4 flex w-full items-center">
            <div className="w-[20%] h-full flex justify-start">Type</div>
            <div className="w-[30%]"></div>
            <div className=" w-[50%]">
              <FormItem
                name="TypeName"
                rules={[{ required: true, message: "Please input Type Name" }]}
              >
                <AutocompleteComponent
                  placeholder={"Input Type Name"}
                  options={options}
                  onSelect={setAutocompleteVal}
                  notFoundContentOnSelectVal={setAutocompleteVal}
                  notFoundContent="Add Type"
                  notFoundContentModal={productPopUpModal}
                  style={{
                    width: "258px",
                    display: "flex",
                    justifyContent: "start",
                  }}
                />
              </FormItem>
            </div>
          </div>
          <div className="p-4 flex w-full items-center">
            <div className="w-[20%] h-full flex justify-start">Series</div>
            <div className="w-[30%]"></div>
            <div className=" w-[50%]">
              <FormItem
                name="SeriesName"
                rules={[
                  { required: true, message: "Please select Series Name" },
                ]}
              >
                <DropdownComponent2
                  defaultSelected=""
                  style={{ width: "100%", textAlign: "initial" }}
                  options={[
                    { value: "Brand1", label: "Brand1" },
                    { value: "Brand2", label: "Brand2" },
                  ]}
                />
              </FormItem>
            </div>
          </div>
          <div className="p-4 flex w-full items-center">
            <div className="w-[20%] h-full flex justify-start">Category</div>
            <div className="w-[30%]"></div>
            <div className=" w-[50%]">
              <FormItem
                name="CategoryName"
                rules={[
                  { required: true, message: "Please select Category Name" },
                ]}
              >
                <DropdownComponent2
                  defaultSelected=""
                  style={{ width: "100%", textAlign: "initial" }}
                  options={[
                    { value: "Brand1", label: "Brand1" },
                    { value: "Brand2", label: "Brand2" },
                  ]}
                />
              </FormItem>
            </div>
          </div>
        </div>
        <div className="w-full shadow-md shadow-neutral-600 rounded-xl flex items-start flex-col text-lg font-semibold">
          <div className="p-4 flex w-full items-center">
            <div className="w-[20%] h-full flex justify-start">
              Product Pictures
            </div>
            <div className="w-[30%]"></div>
            <div className=" w-[50%]">
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Dragger>
            </div>
          </div>
          <div className="p-4 flex w-full items-center">
            <div className="w-[20%] h-full flex justify-start">Description</div>
            <div className="w-[30%]"></div>
            <div className=" w-[50%]">
              <TextArea rows={4} />
            </div>
          </div>
          <div className="p-4 flex w-full items-center">
            <div className="w-[20%] h-full flex justify-start">
              Specification
            </div>
            <div className="w-[30%]"></div>
            <div className=" w-[50%]">
              <TextArea rows={4} />
            </div>
          </div>
        </div>
        <div className="w-full shadow-md shadow-neutral-600 rounded-xl flex items-start flex-col text-lg font-semibold">
          <div className="p-4 flex w-full items-center">
            <div className="w-[20%] h-full flex justify-start">
              Variant Pictures
            </div>
            <div className="w-[30%]"></div>
            <div className=" w-[50%]">
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Dragger>
            </div>
          </div>
        </div>
      </Form>
      <div className="flex mt-3 h-[5%] w-[100%] justify-center items-center gap-6">
        <ButtonComponent
          buttonLabel="Confirm"
          form="ProductPopup"
          htmlType={"submit"}
          buttonOnClick={() => undefined}
          buttonBoolVal={productModal}
          style={"h-[45px] w-[120px] text-lg bg-[#5ec5ff] text-white font-bold"}
        />
        <ButtonComponent
          buttonLabel="Cancel"
          htmlType={"button"}
          buttonOnClick={cancelButtonOnClick}
          buttonBoolVal={productModal}
          style={"h-[45px] w-[120px] text-lg text-black font-bold"}
        />
      </div>
    </div>
  )
}

export default PopUpProduct
